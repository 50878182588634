import React from "react";
import styled from "styled-components";
import { Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Home from "./views/Home.js";
import BrandGuidelines from "./views/BrandGuidelines/BrandGuidelines.js";
import BrandPositioning from "./views/BrandGuidelines/Chapters/BrandPositioning.js";
import VisualIdentity from "./views/BrandGuidelines/Chapters/VisualIdentity.js";
import Typography from "./views/BrandGuidelines/Chapters/Typography.js";
import BrandApplication from "./views/BrandGuidelines/Chapters/BrandApplication.js";
import Imprint from "./views/Legal/Imprint.js";
import PrivacyPolicy from "./views/Legal/PrivacyPolicy.js";

function Container({ location }) {
  return (
    <Wrapper id="main">
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          timeout={{ enter: 500, exit: 500 }}
          classNames={"fade"}
        >
          <Switch location={location}>
            <Route exact path="/" component={Home} />
            <Route exact path="/brand-guidelines" component={BrandGuidelines} />
            <Route
              exact
              path="/brand-guidelines/brand-positioning"
              component={BrandPositioning}
            />
            <Route
              exact
              path="/brand-guidelines/visual-identity"
              component={VisualIdentity}
            />
            <Route
              exact
              path="/brand-guidelines/typography-and-colors"
              component={Typography}
            />
            <Route
              exact
              path="/brand-guidelines/brand-application"
              component={BrandApplication}
            />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/imprint" component={Imprint} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  div.transition-group {
    position: relative;
  }
  section.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

export default withRouter(Container);
