import React, { Component } from "react";

class SectionVoice extends Component {
  render() {
    return (
      <div className="pt4 pb7 mt7-5">
        <div className="wrap color-petrol">
          <p className="color-candy h6 bold uppercase">1.4 Brand Voice</p>
          <div className="title mt1 mb1-5">
            How do we speak to our audience?
          </div>
          <p className="h3 font-serif mb1 bold">Our voice is direct</p>
          <p className="body mb3">
            We don’t shy away from the facts, or truth. Our voice is clear,
            direct and never misleading. We aim for clarity and understanding.
          </p>

          <p className="h3 font-serif mb1 bold">Our voice is simple</p>
          <p className="body mb3">
            Complex topics sometime need clarity. Our voice is simple, we don’t
            need jargon and we thrive in being as understandable as we can.
          </p>

          <p className="h3 font-serif mb1 bold">Our voice is friendly</p>
          <p className="body">
            Being serious and trusted, does not mean being cold. We want our
            audience to see our warm human side as well.
          </p>
        </div>
      </div>
    );
  }
}

export default SectionVoice;
