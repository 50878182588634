import React, { Component } from "react";
import SVG from "react-inlinesvg";
import logoFull from "./img/logoFull.svg";
import mark from "./img/mark.svg";
import markH from "./img/mark-horiz.svg";

class SectionLogotype extends Component {
  render() {
    return (
      <div className="pt9 pb5">
        <div className="wrap color-petrol">
          <p className="color-candy h6 bold uppercase">2.3 The Logotype</p>
          <div className="title mt1 mb1-5">
            Simple, structured and yet recognizable
          </div>
          <p className="body mb3">
            The main idea behind the logo is the aknowledgement that
            Breakthrough would not be breakthrough without its two main sources:
            the patients, and the data. The logo represents the union of these
            two worlds, metaphorically represented by two dots and two squares.
            <br />
            <br />
            Other values such as scientific look and feel, order and precision
            come from a custom designed geometrical typography, that pairs well
            with the grid of the logo by adding a scientific twist to the
            composition.
            <br />
            <br />
            The mark is supposed to be used alongside the typemark in all cases
            where recognizability is a must (official documents, first
            touch-points such as website or printed materials).
          </p>

          <div className="block center my4">
            <SVG src={logoFull} />
            <p className="mt7-5 h6 medium uppercase">Mark + Logotype</p>
          </div>

          <p className="body mb3">
            In some context, as well as in later stages when the brand will be
            more established, the use of the simple mark will be enough. On
            marketing materials, stationary or even merchandise we suggest a
            heavy use of the shapes and the mark as its own entity, so that the
            brand awareness can be enhanced. You can see some of the
            applications in chapter 4.
          </p>

          <div className="center my4 grid grid-2 align-center">
            <div>
              <SVG src={mark} />
              <p className="mt7-5 mb4 h6 medium uppercase">
                Simple Mark Square
              </p>
            </div>
            <div>
              <SVG src={markH} />
              <p className="mt7-5 mb4 h6 medium uppercase">Simple Mark Line</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionLogotype;
