import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import SVG from "react-inlinesvg";
import logo from "./../img/logoFull.svg";
import burger from "./../img/burger.svg";
import { scroll } from "./../misc/Scroll.js";
import styled from "styled-components";
import { color } from "../styles/Variables";
import pattern from "./../img/patternWhite.svg";

const HeaderDiv = styled.header`
  top: 0;
  z-index: 89;
  width: 100%;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0), 0 0 24px 0 rgba(0, 0, 0, 0);
  background-color: rgba(255, 255, 255, 0.9);
  border-style: solid;
  border-width: 0.5px;
  border-image-source: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.4) 5%,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0)
  );
  border-image-slice: 1;
  transition: 0.5s ease-in;

  &.fixed {
    position: fixed;

    #nav {
      display: initial;
    }

    @media (max-width: 767px) {
      .logo {
        transform: scale(0.7);
        transform-origin: left center;
      }
    }
  }

  &:not(.fixed) {
    #nav,
    #burger {
      display: none;
    }

    .isvg {
      svg {
        .lettering {
          display: none;
        }
      }
    }
  }

  &.shadow {
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.02), 0 0 24px 0 rgba(0, 0, 0, 0.08);
  }

  &[data-burger="feed-me"] {
    #burger {
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 89;

      svg {
        path {
          transition: 0.5s ease;
        }
      }

      .BurgerFill {
        path {
          &:nth-child(1) {
            transform: translateX(100%);
            transition-delay: 0s;
          }
          &:nth-child(2) {
            transform: translateX(-100%);
            transition-delay: 0.1s;
          }
          &:nth-child(3) {
            transform: translateX(100%);
            transition-delay: 0s;
          }
        }
      }

      &:not(.visible):hover {
        &.hoverable {
          .BurgerFill {
            path {
              transform: translateX(0%);
            }
          }
        }
      }

      &.visible {
        .BurgerBase {
          fill: white;

          path {
            transition: 0.5s ease;

            &:nth-child(1) {
              transform: rotate(45deg) translate(0, 8px);
              transform-origin: center;
            }

            &:nth-child(2) {
              transform: scaleX(0);
              transform-origin: center;
            }

            &:nth-child(3) {
              transform: rotate(-45deg) translate(0, -8px);
              transform-origin: center;
            }
          }
        }
      }
    }

    #nav {
      display: initial;
      background: ${color.petrol};
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      padding: 2rem 4rem;
      text-align: center;
      opacity: 0;
      transition: 0.4s ease-out;
      pointer-events: none;

      a {
        color: white;
      }

      &.visible {
        opacity: 1;
        pointer-events: initial;
      }

      @media (max-width: 767px) {
        padding: 82px 1rem 2rem;

        p {
          margin-bottom: 1.3rem;
        }

        p:not(.btn),
        a {
          font-size: 2em;
          font-weight: bold;
        }

        a:hover {
          color: ${color.salmon};
        }

        &::before {
          content: "";
          position: absolute;
          z-index: -1;
          bottom: -50%;
          left: 50%;
          width: 100%;
          height: 100%;
          transform: rotate(45deg) scale(11) translate(45%, -47%);
          background: url(${pattern}) space 0 0;
          pointer-events: none;
        }
      }
    }
  }

  > .wrap {
    max-width: 76.5%;

    @media (max-width: 767px) {
      max-width: 86.8%;
    }
  }

  @media (min-width: 768px) {
    .logo {
      padding-top: 6px;
    }
  }
`;

class Header extends Component {
  constructor() {
    super();
    this.updateWidth = this.updateWidth.bind(this);
    this.handleClickHome = this.handleClickHome.bind(this);
    this.handleClickCareers = this.handleClickCareers.bind(this);
    this.listenScrollEvent = this.listenScrollEvent.bind(this);
    this.feedMe = this.feedMe.bind(this);
    this.state = {
      shadow: false,
      burger: window.innerWidth < 768 ? true : false,
    };
  }

  listenScrollEvent = () => {
    if (
      document.getElementById("anchor") &&
      window.scrollY > document.getElementById("anchor").offsetTop
    ) {
      document.getElementById("header").classList.add("shadow");
    } else {
      document.getElementById("header").classList.remove("shadow");
    }
  };

  handleClickHome = event => {
    event.preventDefault();

    scroll(0);
    this.feedMe();
  };

  handleClickCareers = event => {
    event.preventDefault();
    const el = document.querySelector("#careers");

    scroll(el.offsetTop);
    this.feedMe();
  };

  feedMe = () => {
    document.getElementById("nav").classList.toggle("visible");
    document.getElementById("burger").classList.toggle("visible");

    if (document.getElementsByClassName("heroShapes")[0]) {
      document
        .getElementsByClassName("heroShapes")[0]
        .classList.toggle("greyed");
    }
  };

  updateWidth = () => {
    if (window.innerWidth < 768) {
      this.setState({ burger: true });
    } else {
      this.setState({ burger: false });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateWidth);
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  render() {
    return (
      <Fragment>
        <HeaderDiv id="header" data-burger={this.state.burger ? "feed-me" : ""}>
          <div className="wrap py-1-25 pmy1 relative">
            <Link to="/" className="logo inline-block v-align">
              <SVG src={logo}>
                <img src={logo} alt="breakthrough logo" />
              </SVG>
            </Link>

            {this.state.burger ? (
              <div
                id="burger"
                onClick={this.feedMe}
                className={!isMobile ? "hoverable" : ""}
              >
                <SVG src={burger} />
              </div>
            ) : (
              ""
            )}

            <nav
              id="nav"
              className="inline-block v-align right"
              style={{ transform: 'translateY("7px")' }}
            >
              <div>
                <div
                  className="grid grid-2"
                  style={
                    window.innerWidth >= 768
                      ? { gridTemplateColumns: "1fr 1fr" }
                      : {}
                  }
                >
                  <p className="show-m">
                    <a
                      href="/"
                      title="Link to Home"
                      className="block regular"
                      style={{ padding: "0.7rem 2rem" }}
                      onClick={this.handleClickHome}
                    >
                      Home
                    </a>
                  </p>
                  <p>
                    <a
                      href="/#careers"
                      title="Link to Careers"
                      rel="noopener noreferrer"
                      className="block regular"
                      style={{ padding: "0.7rem 2rem" }}
                      onClick={this.handleClickCareers}
                    >
                      Careers
                    </a>
                  </p>
                  <p
                    className="do-modal pointer btn btn-blushpink btn-blushpink--alt"
                    style={{
                      minWidth: "135px",
                      width: "auto",
                      justifySelf: "center",
                    }}
                  >
                    Get in touch
                  </p>
                </div>
              </div>
            </nav>
          </div>
        </HeaderDiv>
        <div id="anchor" />
      </Fragment>
    );
  }
}

export default Header;
