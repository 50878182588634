import React, { Component } from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 668px;
  margin-bottom: 8rem;
`;

class Imprint extends Component {
  render() {
    return (
      <section className={`wrap pt13`}>
        <Container>
          <h1 className="h1 font-serif bold break-word color-petrol mb1">
            Imprint
          </h1>
          <p className="body line-height-4 color-wg">
            <span className="bold">BreakthroughX Health GmbH</span>
            <br />
            Rosenthaler Strasse 13
            <br />
            10119 Berlin
            <br />
            Germany
            <br />
            Tel.:{" "}
            <a href="tel:+4915255411112" className="link color-wg">
              +49 152 554 11112
            </a>
            <br />
            Email:{" "}
            <a href="mailto:info@breakthrough.health" className="link color-wg">
              info@breakthrough.health
            </a>
            <br />
            <br />
            Managing Directors:
            <br />
            Bazil Azmil
            <br />
            Stefano Palazzo
            <br />
            <br />
            Registration Court: Amtsgericht Berlin (Charlottenburg) <br />
            Registration No.: HRB 192700 B <br />
            Seated in Berlin
            <br />
            <br />
            VAT ID: DE315899714
          </p>
        </Container>
      </section>
    );
  }
}

export default Imprint;
