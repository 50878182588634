import { css } from "styled-components";

export class Property {
  constructor(number, unit) {
    this.number = number;
    this.unit = unit;
  }
  get css() {
    return `${this.number}${this.unit}`;
  }
  inPixel(parentElement = document.documentElement) {
    switch (this.unit) {
      default:
      case "px":
        return this.number;
      case "em":
        return (
          this.number * parseFloat(getComputedStyle(parentElement).fontSize)
        );
      case "rem":
        return (
          this.number *
          parseFloat(getComputedStyle(document.documentElement).fontSize)
        );
      case "vw":
        return (this.number * window.innerWidth) / 100;
      case "vh":
        return (this.number * window.innerHeight) / 100;
    }
  }
}

export const breakpointLabels = ["xs", "sm", "md", "lg", "xl"];
export const breakpoints = [600, 900, 1200, 1800];

export class ResponsiveStruct {
  constructor(unit, ...values) {
    this.values = values;
    this.unit = unit;
  }
  css(property, unit = this.unit) {
    return css`
			${property}: ${this.values[4]}${unit};
			@media screen and (max-width: ${breakpoints[0]}px) {
				${property}: ${this.values[0]}${unit};
			}
			@media screen
				and (min-width: ${breakpoints[0]}px)
				and (max-width: ${breakpoints[1]}px)
			{
				${property}: ${this.values[1]}${unit};
			}
			@media screen
				and (min-width: ${breakpoints[1]}px)
				and (max-width: ${breakpoints[2]}px)
			{
				${property}: ${this.values[2]}${unit};
			}
			@media screen
				and (min-width: ${breakpoints[2]}px)
				and (max-width: ${breakpoints[3]}px)
			{
				${property}: ${this.values[3]}${unit};
			}
		`;
  }
}

export const containerWidths = breakpoints.map(v => v * 0.955);
containerWidths[3] = 1440;
export const gutterWidth = 30;

export const media = breakpoints.reduce((acc, value, index) => {
  // prettier-ignore
  acc[breakpointLabels[index]] = (...args) => css`@media (max-width: ${value}px) {${css(...args)};}`;
  return acc;
}, {});

export const typography = {
  size: new ResponsiveStruct("px", 15, 15, 15, 15, 15),
};

export const color = {
  charcoal_grey: "#3c3f40",
  salmon: "#ff7a8a",
  lagoon: "#055777",
  sky: "#60bcf4",
  petrol: "#002748",
  candy: "#f7bfdb",
  cotton: "#fcebf4",
  warm_grey: "#999999",
  white: "#fff",
  marine: "#062743",
  pinkish_grey: "#cccccc",
  pinkish_red: "#ef1e38",
};

export const zindex = [
  "navigation",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
].reduce((acc, key, index, arr) => {
  // This transforms the array above into an object
  // an object of z-index values with array element as keys
  const max = arr.length;
  acc[key] = max - index;
  return acc;
}, {});
