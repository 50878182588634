import React, { Component } from "react";

class SectionStatement extends Component {
  render() {
    return (
      <div className="bg-petrol color-white py13 mt7-5">
        <div className="wrap">
          <div>
            <p className="color-candy h6 bold uppercase">1.1 Brand Statement</p>
            <div className="title col-white mt1 mb1-5">
              Accelerating research together.
            </div>
            <p className="body">
              Breakthrough’s purpose is to accelerate research and development
              in the health industry. This is done by empowering patients with
              the understanding and use of their data, and by bridging patients
              with research institutions and doctors/hospitals. These three
              parties, together, can achieve more than they could separately.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionStatement;
