import React, { Component } from "react";
import Hero from "./../../../components/Hero.js";
import SectionBrandIdentity from "./VisualIdentity/SectionBrandIdentity.js";
import SectionConcept from "./VisualIdentity/SectionConcept.js";
import SectionLogotype from "./VisualIdentity/SectionLogotype.js";
import NextChapter from "./../../../components/NextChapter.js";
import styled from "styled-components";

const SectionWrapper = styled.div`
  > div > div {
    width: 50%;

    @media (max-width: 1024px) {
      width: 70%;
    }

    @media (max-width: 767px) {
      width: 90%;
    }
  }
`;

class VisualIdentity extends Component {
  render() {
    return (
      <SectionWrapper>
        <Hero
          title={"Visual Identity"}
          text={
            "How does the core of Breakthrough look like? \nWhat is its look and feel?"
          }
        />
        <SectionBrandIdentity />
        <SectionConcept />
        <SectionLogotype />

        <NextChapter
          title="Typography &amp; Color"
          text="The baseline of Breakthrough's identity, its typography and colors."
          link="/brand-guidelines/typography-and-colors"
        />
      </SectionWrapper>
    );
  }
}

export default VisualIdentity;
