import React, { Component } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import arrow from "./../img/arrow.svg";
import { scroll } from "./../misc/Scroll.js";

const Container = styled.div`
  max-width: 640px;
`;

const Cta = styled.a`
  span {
    display: inline-block;
    position: relative;
    top: -4px;
    animation: scrollMe 3s infinite ease-in;
  }

  @keyframes scrollMe {
    0% {
      transform: translateY(20%);
    }
    10% {
      transform: translateY(27%);
    }
    20% {
      transform: translateY(20%);
    }
    100% {
      transform: translateY(20%);
    }
  }
`;

class Hero extends Component {
  handleClick = event => {
    const href = event.target.getAttribute("href");
    const el = document.querySelector(href);

    scroll(el.offsetTop - 120);
  };
  render() {
    const cta = this.props.withCta ? (
      <Cta
        href="#learn-more"
        className="mt3 my0-5 btn btn-blushpink"
        onClick={this.handleClick}
      >
        Learn more
        <span id="scrollme" className="ml1" href="#learn-more">
          <SVG src={arrow} />
        </span>
      </Cta>
    ) : (
      ""
    );

    return (
      <section
        className={`hero wrap ${this.props.className} pt13 pb5 mb2 pmt9`}
      >
        <Container>
          <h1 className="h1 font-serif bold break-word color-petrol mb2">
            {this.props.title}
          </h1>
          <p className="body line-height-4 color-wg">{this.props.text}</p>

          {cta}
        </Container>
      </section>
    );
  }
}

export default Hero;
