import React, { Component } from "react";
import SVG from "react-inlinesvg";
import close from "./../img/close.svg";
import styled from "styled-components";
import { color } from "../styles/Variables";
import Form from "./Form.js";

const ModalDiv = styled.div`
  position: fixed;
  z-index: 101;
  background: rgba(6, 39, 67, 0.8);
  top: -100%;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: block;
  overflow: scroll;
  text-align: center;
  animation: moveIn 0.5s forwards ease-out;

  @keyframes moveIn {
    0% {
      opacity: 0;
      top: -100%;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }

  input,
  textarea {
    width: 100%;
    margin-bottom: 24px;
    color: ${color.petrol};
    transition: 0.5s ease-in;

    &:not([type="submit"]) {
      border-color: ${color.pinkish_grey};
    }

    &:focus,
    &:active {
      border-color: ${color.petrol};
    }

    ~ .invalid {
      position: absolute;
      opacity: 0;
      transition: 0.5s ease-in;

      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      color: white;
      background: #ef1e38;
      left: 0;
      top: calc(100% - 21px);
      padding: 0.57rem 1.5rem;
      border-radius: 8px;
      z-index: 10;

      &::after {
        bottom: 100%;
        left: 20%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &::after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: #ef1e38;
        border-width: 10px;
        margin-left: -10px;
      }
    }
  }

  input[type="submit"] {
    color: ${color.white};

    &:hover,
    &:active,
    &:focus {
      color: ${color.salmon};
      border-color: ${color.salmon};
    }
  }

  .submitted input:required:invalid,
  .submitted textarea:required:invalid {
    color: green;
    color: ${color.petrol};
    background-color: rgba(239, 30, 56, 0.08);
    border-color: ${color.pinkish_red};

    ~ .invalid {
      opacity: 1;
    }
  }

  .modal-inner {
    position: relative;
    display: inline-block;
    width: calc(100% - 64px);
    max-width: 580px;
    margin: 32px auto;
    vertical-align: middle;

    @media (max-width: 767px) {
      width: calc(100% - 24px);
      max-width: 400px;
      margin: 48px auto;
    }
  }

  &::before {
    display: inline-block;
    margin-right: -0.25em;
    height: 100%;
    content: "";
    vertical-align: middle;
  }
`;

const ModalClose = styled.p`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 99;

  g {
    transition: 0.3s ease;
  }

  &:hover {
    g {
      fill: ${color.petrol};
    }
  }
`;

const Response = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  opacity: 0;
  transition: 0.3s ease;

  &:not(.given),
  &:not(.error) {
    pointer-events: none;
  }

  &.error,
  &.given {
    opacity: 1;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    height: 100%;
    padding: 4rem 2rem;
  }

  &.given {
    > div:nth-child(2) {
      display: none;
    }
  }

  &.error {
    > div:nth-child(1) {
      display: none;
    }
  }

  display: grid;
  align-content: center;

  > div {
    display: inline-block;

    a {
      pointer-events: initial;
    }
  }
`;

class Modal extends Component {
  componentDidMount() {
    document
      .getElementsByTagName("body")[0]
      .setAttribute("style", "overflow:hidden;height:100%;");
  }

  state = {
    showResult: false
  };
  onSubmit = () => {
    this.setState({ showResult: true });
  };

  render() {
    const Modal = (
      <ModalDiv className={`grid align-center justify-center`}>
        <div className="modal-inner bg-white px3-5 pmx2 py4 center center relative">
          <div className="inline-block px-2 w-100">
            <ModalClose id="modalClose" className="pointer">
              <SVG src={close} />
            </ModalClose>
            <p className="h3 mb3">Get in touch</p>

            <Form onSubmit={this.onSubmit} />

            {this.state.showResult && (<Response id="response">
              <div className="px3 pmx0 success">
                <p className="h3 col-navy">Your message has been sent</p>
                <p className="body col-wg mt2 ">
                  Thanks for getting in touch. We will get back to you as soon as possible.
                </p>
                <a href="/" className="btn btn-blushpink mt3">
                  Got it
                </a>
              </div>
              <div className="px3 pmx0 error">
                <p className="h3 col-navy">An error as occured!</p>
                <p className="body col-wg mt2 ">
                  Sorry for the inconvenience. If the problem persists, you can always contact us at <a
                  href="mailto:contact@breakthrough.health">contact@breakthrough.health</a>.
                </p>
                <a href="/" className="btn btn-blushpink mt3">
                  Got it
                </a>
              </div>
            </Response>)}
          </div>
        </div>
      </ModalDiv>
    );

    return Modal;
  }
}

export default Modal;
