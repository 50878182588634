import React, { Component } from "react";
import styled from "styled-components";

const { SparkScroll } = require("react-spark-scroll-gsap")({
  invalidateAutomatically: true,
});

const Container = styled.div`
  position: relative;
  left: 0;
  transition: 0.3s ease;
  transform: translateY(40%);
  box-shadow: 0 -9px 34px 0 rgba(0, 39, 72, 0.09);

  &:hover {
    transform: translateY(35%);
    box-shadow: 0 -9px 34px 0 rgba(0, 39, 72, 0.27);
  }

  .title {
    font-size: 3.5rem;
  }

  @media (max-width: 767px) {
    text-align: center;

    .getintouch {
      display: block;
      margin: 2rem auto 0;

      > div {
        width: 100%;
      }
    }

    .w-80 {
      width: 100%;
    }

    .title {
      font-size: 2rem;
    }
  }
`;

class NextChapter extends Component {
  componentDidMount() {
    document.getElementById("footer").classList.add("pt9");
  }
  componentWillUnmount() {
    document.getElementById("footer").classList.remove("pt9");
  }
  render() {
    return (
      <SparkScroll.section
        className="relative"
        timeline={{
          ease: "easeIn",
          topBottom: { top: "120px" },
          bottomCenter: { top: "0px" },
        }}
      >
        <Container className="wrap bg-white color-navy">
          <a
            href={this.props.link}
            className="block color-white py3-5 pmy3 px4 pmx2 pmy2"
          >
            <div className="w-60 wm-90 inline-block v-align">
              <div className="title font-base regular mb1-5">
                {this.props.title}
              </div>
              <p className="body body-m color-wg">{this.props.text}</p>
            </div>

            <div className="getintouch inline-block v-align w-40 wm-100 right-align">
              <div className="inline-block right-align btn btn-blushpink">
                Get in touch
              </div>
            </div>
          </a>
        </Container>
      </SparkScroll.section>
    );
  }
}

export default NextChapter;
