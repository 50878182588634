import React, { Component } from "react";

class SectionChoices extends Component {
  render() {
    return (
      <div className="pt13 pb7">
        <div className="wrap color-petrol">
          <p className="color-candy h6 bold uppercase">
            3.2 Typographical choices choices
          </p>
          <div className="title mt1 mb1-5">Emotion meets rational</div>
          <p className="h3 font-serif mb1 bold">Yrsa Bold</p>
          <p className="body mb3">
            As the main headline font we went for the Yrsa. It’s humanistic
            serif touch conveys both a more serious approach, but at the same
            time having a little emotional/human touch.
          </p>

          <p className="h3 medium mb1">Manifold Medium</p>
          <p className="body mb3">
            In order to keep the balance, we paired the Yrsa with a more
            technical rational manifold, which has a very strong
            scientific/tech look and feel. The manifold is used mostly for the
            small typography, Interaction and the textes of the website.
          </p>
        </div>
      </div>
    );
  }
}

export default SectionChoices;
