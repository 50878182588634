import React, { Component } from "react";

class SectionObjectives extends Component {
  render() {
    return (
      <div className="py13">
        <div className="wrap color-petrol">
          <p className="color-candy h6 bold uppercase">1.2 Brand Objectives</p>
          <div className="title mt1 mb1-5">What is our mission?</div>
          <p className="h3 font-serif mb1 bold">To empower individuals</p>
          <p className="body mb3">
            We want to empower individuals and let them take control of their
            data. Our ultimate customer will always be our users.
          </p>

          <p className="h3 font-serif mb1 bold">To accelerate R/D</p>
          <p className="body mb3">
            Our goal is to accelerate the research in the medical field. We aim
            to find ways to improve the current state of research.
          </p>

          <p className="h3 font-serif mb1 bold">To reduce the global burden</p>
          <p className="body">
            Our higher level goal is to reduce the global burden of healthcare
            costs that stem from chronical diseases.
          </p>
        </div>
      </div>
    );
  }
}

export default SectionObjectives;
