import React, { Component } from "react";
import Hero from "./../../../components/Hero.js";
import SectionConcept from "./Typography/SectionConcept.js";
import SectionChoices from "./Typography/SectionChoices.js";
import SectionPalette from "./Typography/SectionPalette.js";
// import NextChapter from "./../../../components/NextChapter.js";
import styled from "styled-components";

const SectionWrapper = styled.div`
  > div > div {
    width: 50%;

    @media (max-width: 1024px) {
      width: 70%;
    }

    @media (max-width: 767px) {
      width: 90%;
    }
  }
`;

class VisualIdentity extends Component {
  render() {
    return (
      <SectionWrapper>
        <Hero
          title={"Typography & Color"}
          text={
            "The baseline of Breakthrough's identity, its typography and colors."
          }
        />
        <SectionConcept />
        <SectionChoices />
        <SectionPalette />

        {/* <NextChapter
          title="Brand Application"
          text="How to apply the brand in various contexts."
          link="/brand-guidelines/brand-application"
        /> */}
      </SectionWrapper>
    );
  }
}

export default VisualIdentity;
