import React from "react";

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: " ",
      email: " ",
      company: " ",
      message: " ",
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = e => {
    e.preventDefault();

    const form = {
      name: this.state.name,
      email: this.state.email,
      company: this.state.company,
      message: this.state.message,
    };
    // console.log("form", form, JSON.stringify(form));

    document.getElementById("form").className = "submitted";

    let _input = document.querySelectorAll(".input-required");
    let _count = 0;

    for (let i = 0; i < _input.length; i++) {
      if (_input[i].value.length > 0) {
        _count++;
      }
    }

    if (_count === _input.length) {
      fetch("https://prod-api-01.emilyn.app/api/v4/contact", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(form),
      })
        .then(response => {
          // console.log("Success:", response);

          this.props.onSubmit();
          document.getElementById("response").classList.add("given");
          this.setState({
            name: "",
            email: "",
            company: "",
            message: "",
          });
        })
        .catch(error => {
          // console.error("Error:", error);
          this.props.onSubmit();
          document.getElementById("response").classList.add("error");
        });
    }
  };

  render() {
    return (
      <div>
        <form id="form">
          <p className="relative">
            <input
              className="input-required"
              required
              type="text"
              placeholder="Your Name*"
              name="name"
              onChange={e => this.handleChange(e)}
            />
            <span className="invalid">This field is mandatory</span>
          </p>
          <p className="relative">
            <input
              className="input-required"
              required
              type="email"
              placeholder="Your Email*"
              name="email"
              onChange={e => this.handleChange(e)}
            />
            <span className="invalid">This field is mandatory</span>
          </p>
          <p>
            <input
              className=""
              type="text"
              placeholder="Company"
              name="company"
              onChange={e => this.handleChange(e)}
            />
          </p>
          <p className="relative">
            <textarea
              className="input-required"
              required
              cols="30"
              rows="10"
              resizable="true"
              placeholder="Your message*"
              name="message"
              onChange={e => this.handleChange(e)}
            />
            <span className="invalid">This field is mandatory</span>
          </p>
          <div className="w-100 right-align mt1">
            <div className="inline-block v-align right-align wm-100">
              <button
                className="inline-block right-align btn btn-blushpink"
                type="submit"
                onClick={e => this.onSubmit(e)}
                id="submit"
              >
                Send message
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Form;
