import { injectGlobal } from "styled-components";

import "./animations.css";
import "./components.css";
import "./grid.css";
import "./layout.css";
import "./opacity.css";
import "./margin.css";
import "./padding.css";
import "./reset.css";
import "./typescale.css";
import "./typo.css";

import { color } from "./Variables.js";

injectGlobal`
html,
body {
	font-family: ManifoldCF, Arial, sans-serif;
	font-size: 16px;
	line-height: 1.5;
	overflow-x: hidden;

	@media (max-width: 767px) {
		/* font-size: 9px; */
	}
}

#root {
	position: relative;
	z-index: 1;
}

.parallax-outer.wrap .wrap {
	max-width: 100%;
}

.parallx-outer,
.parallax-inner,
.parallx-outer > .parallax-inner {
	width: 100% !important;
}

a {
	color: initial;
	text-decoration: none;
	outline: none;
}

a.op0-7 {
	transition: 0.2s ease;

	&:hover,
	&:active,
	&:focus {
		opacity: 1;
	}
}

a.link {
	&:hover {
		text-decoration: underline
	}
}

button,
.btn {
	border: 0;
	border-radius: 10em;
	color: ${color.white};
	cursor: pointer;
	display: inline-block;
	font-size: 1.25rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	padding: 1.125rem 1.8rem;
	outline: none;
	transition all .3s ease;
	-webkit-appearance: none;

	&-sky {
		box-shadow: 0 4px 24px 0 rgba(0,0,0,0.02), 0 0 24px 0 rgba(0,0,0,0.08);
		background: ${color.sky};
		border: 1px solid ${color.sky};

		svg {
			g {
				stroke: ${color.white};
				transition all .3s ease;
			}
		}

		&:hover,
		&:focus,
		&:active {
			background: transparent;
			color:  ${color.sky};

			svg {
				g {
					stroke: ${color.sky};
				}
			}
		}
	}

	&-blushpink {
		box-shadow: 0 4px 24px 0 rgba(0,0,0,0.02), 0 0 24px 0 rgba(0,0,0,0.08);
		background: ${color.salmon};
		border: 1px solid ${color.salmon};

		svg {
			g {
				stroke: ${color.white};
				transition all .3s ease;
			}
		}

		&:hover,
		&:focus,
		&:active {
			background: transparent;
			color:  ${color.salmon};

			svg {
				g {
					stroke: ${color.salmon};
					transition all .3s ease;
				}
			}
		}

		&--alt {
			box-shadow: none;
			font-size: 1rem;
			padding: 0.7rem 1rem;
			background: transparent;
			border: 1px solid ${color.salmon};
			color: ${color.salmon};

			&:hover,
			&:active,
			&:focus {
				box-shadow: 0 4px 24px 0 rgba(0,0,0,0.02), 0 0 24px 0 rgba(0,0,0,0.08);
				background: ${color.salmon};
				color: ${color.white};
			}
		}
	}
}

input,
textarea {
	background: transparent;
	border: 1px solid rgba(255, 255, 255, .7);
	padding: 1rem 1.5rem;
	border-radius: 24px;
	/* width: 100%; */
	font-size: 1rem;
	letter-spacing: 0.4px;
	color: white;
	outline: none;
	transition all .3s ease;
	-webkit-appearance: none;
	font-family: ManifoldCF, Arial, sans-serif;

	&:focus,
	&:active {
		border: 1px solid rgba(255, 255, 255, 1);
		/* color: red; */
	}

	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		opacity: .7; /* Firefox */
	}

	:-ms-input-placeholder { /* Internet Explorer 10-11 */
		opacity: .7;
	}

	::-ms-input-placeholder { /* Microsoft Edge */
		opacity: .7;
	}
}
`;

//
// Make each defined color a class for background-color and color
//

injectGlobal([Object.keys(color).reduce((acc,key)=>{
	const split = key.split('_');
	const name = split.length > 1 ? split.reduce((acc,str)=>`${acc}${str[0]}`,'') : key;
	const _color = color[key]
	return `
		${acc}
		.bg-${name}{
			background-color: ${_color};
		}
		.color-${name}{
			color: ${_color};
		}
		.hover-bg-${name}:hover {
			background-color: ${_color};
		}
		.hover-col-${name}:hover {
			color: ${_color}
		}
		.hover-pointer {
			cursor: pointer;
		}
	`
},"")])

injectGlobal([Object.keys(color).reduce((acc,key)=>{
	const split = key.split('_');
	const name = split.length > 1 ? split.reduce((acc,str)=>`${acc}${str[0]}`,'') : key;
	const _color = color[key]
	return `
		${acc}
		.col-${name}{
			color: ${_color};
		}
		.color-${name}{
			color: ${_color};
		}
		.hover-col-${name}:hover {
			color: ${_color}
		}
		.hover-pointer {
			cursor: pointer;
		}
	`
},"")])
