import React, { Component } from "react";
import { color } from "./../../../../styles/Variables";
import styled from "styled-components";

const Color = styled.div`
  width: 100%;
  height: 280px;
  display: block;
  border-radius: 20px;
  transition: all 0.2s ease-out;

  &:hover {
    box-shadow: 0 0px 24px 0 rgba(0, 39, 72, 0.22);
  }
`;

const ColorSmall = styled.div`
  width: 100%;
  height: 60px;
  display: block;
  border-radius: 20px;
  transition: all 0.2s ease-out;

  &:hover {
    box-shadow: 0 0px 24px 0 rgba(0, 39, 72, 0.22);
  }
`;
class SectionPalette extends Component {
  render() {
    return (
      <div className="pb7">
        <div className="wrap color-petrol">
          <p className="color-candy h6 bold uppercase">3.3 Color Palette</p>
          <div className="title mt1 mb1-5">Classic but Rebel</div>
          <p className="h3 font-serif mb1 bold">Primary Colors</p>
          <div className="grid grid-4" style={{ gridGap: "1rem" }}>
            <div>
              <Color
                style={{
                  background: color.salmon,
                }}
              />

              <div className="mt1">
                <p className="body">Salmon</p>
                <p className="base">#258943</p>
                <br />
                <p className="bold">Primary Cta</p>
                Friendly &amp; Warm
              </div>
            </div>

            <div>
              <Color
                style={{
                  background: color.candy,
                }}
              />

              <div className="mt1">
                <p className="body">Candy</p>
                <p className="base">#F7BFDB</p>
                <br />
                <p className="bold">Accent</p>
                Friendly &amp; Warm
              </div>
            </div>

            <div>
              <Color
                style={{
                  background: color.petrol,
                }}
              />

              <div className="mt1">
                <p className="body">Petrol</p>
                <p className="base">#002748</p>
                <br />
                <p className="bold">Background &amp; Content</p>
                Corporate &amp; Trustful
              </div>
            </div>

            <div>
              <Color
                style={{
                  background: color.white,
                  border: `1px solid ${color.petrol}`,
                }}
              />
              <div className="mt1">
                <p className="body">White</p>
                <p className="base">#FFFFFF</p>
                <br />
                <p className="bold">Background &amp; Space</p>
                Breezy &amp; Lightening
              </div>
            </div>
          </div>

          <p className="h3 font-serif mt4 mb2 mb1 bold">Secondary Colors</p>
          <div className="grid grid-4" style={{ gridGap: "1rem" }}>
            <div>
              <ColorSmall
                style={{
                  background: color.sky,
                }}
              />

              <div className="mt1">
                <p className="body">Sky</p>
                <p className="base">#60BCF4</p>
                <br />
                <p className="bold">Secondary Cta</p>
                Friendly &amp; Bold
              </div>
            </div>
            <div>
              <ColorSmall
                style={{
                  background: color.lagoon,
                }}
              />

              <div className="mt1">
                <p className="body">Lagoon</p>
                <p className="base">#055777</p>
                <br />
                <p className="bold">Darker Accent</p>
                Corporate &amp; Trustful
              </div>
            </div>
            <div>
              <ColorSmall
                style={{
                  background: color.cotton,
                }}
              />

              <div className="mt1">
                <p className="body">Cotton</p>
                <p className="base">#FCEBF4</p>
                <br />
                <p className="bold">Background</p>
                Friendly &amp; Soft
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionPalette;
