import React, { Component } from "react";
import Hero from "./../../../components/Hero.js";
import SectionObjectives from "./BrandPositioning/SectionObjectives.js";
import SectionStatement from "./BrandPositioning/SectionStatement.js";
import SectionPersonality from "./BrandPositioning/SectionPersonality.js";
import SectionVoice from "./BrandPositioning/SectionVoice.js";
import NextChapter from "./../../../components/NextChapter.js";
import styled from "styled-components";

const SectionWrapper = styled.div`
  > div > div {
    width: 50%;

    @media (max-width: 1024px) {
      width: 70%;
    }

    @media (max-width: 767px) {
      width: 90%;
    }
  }
`;
class BrandPositioning extends Component {
  render() {
    return (
      <SectionWrapper>
        <Hero
          title={"Brand Positioning"}
          text={
            "How does Breakthrough position in the market, what are its values and vision."
          }
        />

        <SectionStatement />
        <SectionObjectives />
        <SectionPersonality />
        <SectionVoice />

        <NextChapter
          title="Visual Identity"
          text="How does the core of Breakthrough look like?
What is its look and feel?"
          link="/brand-guidelines/visual-identity"
        />
      </SectionWrapper>
    );
  }
}

export default BrandPositioning;
