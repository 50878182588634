import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import Modal from "./components/Modal.js";
import Container from "./Container.js";
// eslint-disable-next-line
import HackTimer from "./misc/HackTimer.js";

export const changingView = () => {
  window.scrollTo(0, 0);

  if (document.getElementById("header")) {
    document.getElementById("header").classList.remove("fixed");

    window.location.href.indexOf("brand-guidelines") > -1
      ? document.getElementById("header").classList.remove("fixed")
      : document.getElementById("header").classList.add("fixed");
  }

  return null;
};
class App extends Component {
  constructor() {
    super();
    this.modal = this.modal.bind(this);
    this.modalClose = this.modalClose.bind(this);
    this.state = {
      modal: false,
      description: "",
    };
  }

  modal = event => {
    event.preventDefault();

    this.state.modal === false
      ? this.setState({ modal: true })
      : this.setState({ modal: false });

    const modalClose = document.querySelectorAll("#modalClose");

    for (var i = 0; i < modalClose.length; i++) {
      modalClose[i].addEventListener("click", this.modalClose, false);
    }
  };

  modalClose = event => {
    event.preventDefault();

    document.getElementsByTagName("body")[0].setAttribute("style", "");

    this.state.modal === false
      ? this.setState({ modal: true })
      : this.setState({ modal: false });
  };
  componentDidMount() {
    if (document.getElementById("header")) {
      document.getElementById("header").classList.remove("fixed");

      window.location.href.indexOf("brand-guidelines") > -1
        ? document.getElementById("header").classList.remove("fixed")
        : document.getElementById("header").classList.add("fixed");
    }

    const modalClick = document.querySelectorAll(".do-modal");
    for (var i = 0; i < modalClick.length; i++) {
      modalClick[i].addEventListener("click", this.modal, false);
    }
  }

  render() {
    return (
      <Fragment>
        <Router>
          <Fragment>
            <div id="top" className="block" />
            <Header />

            <Route component={changingView} />
            <Container />

            <Footer />

            {this.state.modal ? <Modal /> : ""}
          </Fragment>
        </Router>
      </Fragment>
    );
  }
}

export default App;
