import React, { Component } from "react";

class SectionPersonality extends Component {
  render() {
    return (
      <div className="bg-cotton py13">
        <div className="wrap color-petrol">
          <p className="color-salmon h6 bold uppercase">
            1.3 Brand Personality
          </p>
          <div className="title mt1 mb1-5">How do we appear to the public?</div>
          <p className="h3 font-serif mb1 bold">We are trusted</p>
          <p className="body mb3">
            We want to be trusted by both our users, and research and health
            institutions. We want to appear professional but friendly.
          </p>

          <p className="h3 font-serif mb1 bold">We are scientific</p>
          <p className="body mb3">
            Our methods are scientific. We thrive in perfect executions and
            this needs to be a recognized mark of our brand.
          </p>

          <p className="h3 font-serif mb1 bold">We are inclusive</p>
          <p className="body">
            Aiming high does not mean we want to do this alone. We believe in
            inclusion and give the same attention and care to our users, and our
            partners.
          </p>
        </div>
      </div>
    );
  }
}

export default SectionPersonality;
