import React, { Component } from "react";

import icoLogo from "./../../../img/icons/logo.svg";
import icoColor from "./../../../img/icons/color.svg";
import icoYrsa from "./../../../img/icons/yrsa.svg";
import icoBrand_deck from "./../../../img/icons/brand-deck.svg";
import icoManifold from "./../../../img/icons/manifold.svg";

import fileLogoPack from "./../../../files/BT_logo.zip";
import fileColorPalette from "./../../../files/BT_colorpalette.sketch";
import fileBrandDeckTpl from "./../../../files/BT_deck.key";

class SectionDownloads extends Component {
  render() {
    return (
      <div id="downloads" className="pt3">
        <div className="wrap">
          <h1 className="title color-petrol">Downloads</h1>
          <p className="body medium color-wg">
            Looking for some files? Here is a few links to all you need.
          </p>

          <div className="grid grid-2 mt2-5 mb6-75" style={{ gridGap: "3rem" }}>
            <div className="download py1 grid grid-2 align-center">
              <div className="">
                <span className="inline-block v-align icon mr1">
                  <img
                    src={icoLogo}
                    alt="download attachement"
                    className="h-100 block"
                  />
                </span>
                <div className="inline-block v-align w-70 truncate">
                  <p className="body color-petrol font-serif">Logo Pack</p>
                  <p className="h7 color-wg">
                    Logos in SVG, PNG, EPS. (802 Kb)
                  </p>
                </div>
              </div>
              <div className="justify-end my2">
                <a
                  href={fileLogoPack}
                  target="_blank"
                  title="Download Logos in SVG, PNG, EPS. (802 Kb)"
                  download
                  className="btn btn-blushpink--alt"
                >
                  Download
                </a>
              </div>
            </div>
            <div className="download py1 grid grid-2 align-center">
              <div className="">
                <span className="inline-block v-align icon mr1">
                  <img
                    src={icoColor}
                    alt="download attachement"
                    className="h-100 block"
                  />
                </span>
                <div className="inline-block v-align w-70 truncate">
                  <p className="body color-petrol font-serif">Color Palette</p>
                  <p className="h7 color-wg">Sketch file (133 Kb)</p>
                </div>
              </div>
              <div className="justify-end my2">
                <a
                  href={fileColorPalette}
                  target="_blank"
                  title="Download Color Palette Sketch file (133 Kb)"
                  download
                  className="btn btn-blushpink--alt"
                >
                  Download
                </a>
              </div>
            </div>
            <div className="download py1 grid grid-2 align-center">
              <div className="">
                <span className="inline-block v-align icon mr1">
                  <img
                    src={icoYrsa}
                    alt="download attachement"
                    className="h-100 block"
                  />
                </span>
                <div className="inline-block v-align w-70 truncate">
                  <p className="body color-petrol font-serif">Yrsa Typeface</p>
                  <p className="h7 color-wg">Link to Fonts Google</p>
                </div>
              </div>
              <div className="justify-end my2">
                <a
                  href="https://fonts.google.com/specimen/Yrsa"
                  target="_blank"
                  title="Link to Yrsa Google font"
                  rel="noopener noreferrer"
                  download
                  className="btn btn-blushpink--alt"
                >
                  Go to page
                </a>
              </div>
            </div>
            <div className="download py1 grid grid-2 align-center">
              <div className="">
                <span className="inline-block v-align icon mr1">
                  <img
                    src={icoBrand_deck}
                    alt="download attachement"
                    className="h-100 block"
                  />
                </span>
                <div className="inline-block v-align w-70 truncate">
                  <p className="body color-petrol font-serif">
                    Brand Deck Template
                  </p>
                  <p className="h7 color-wg">Keynote file (646 Kb)</p>
                </div>
              </div>
              <div className="justify-end my2">
                <a
                  href={fileBrandDeckTpl}
                  target="_blank"
                  title="Download Brand Deck Template Keynote file (646 Kb)"
                  download
                  className="btn btn-blushpink--alt"
                >
                  Download
                </a>
              </div>
            </div>
            <div className="download py1 grid grid-2 align-center">
              <div className="">
                <span className="inline-block v-align icon mr1">
                  <img
                    src={icoManifold}
                    alt="download attachement"
                    className="h-100 block"
                  />
                </span>
                <div className="inline-block v-align w-70 truncate">
                  <p className="body color-petrol font-serif">
                    Manifold Typeface
                  </p>
                  <p className="h7 color-wg">Link to You Work For Them</p>
                </div>
              </div>
              <div className="justify-end my2">
                <a
                  href="https://www.youworkforthem.com/font/T5335/manifold-cf"
                  target="_blank"
                  title="Link to Manifold Typeface"
                  rel="noopener noreferrer"
                  download
                  className="btn btn-blushpink--alt"
                >
                  Go to page
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionDownloads;
