import React, { Component } from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 668px;
  margin-bottom: 4rem;
`;

const Content = styled.div`
  h3 {
    font-size: 3rem;
    margin: 2rem 0 1rem;
  }

  h4 {
    font-size: 1.625rem;
    margin: 1rem 0 0.7rem;
    font-weight: 600;
    color: black;
  }

  h5 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  p,
  li {
    font-size: 1.5rem;
    margin: 1.5rem 0;
  }
`;

class PrivacyPolicy extends Component {
  render() {
    return (
      <section className={`wrap pt13`}>
        <Container className="body line-height-4 color-wg">
          <h1 className="h1 font-serif bold break-word color-petrol mb1">
            Privacy Policy
          </h1>

          <Content>
            <p><strong>Note:</strong> You can find the privacy policy for the Emilyn app at <a href="https://privacy.emilyn.app/">https://privacy.emilyn.app/</a>
            </p>
            <p>
              Below we provide you with an overview of what data we collect for
              what purpose and how we ensure the protection of the data{" "}
              <a href="#short">in short</a> and{" "}
              <a href="#detailed">in a more detailed form</a>.
            </p>
            <p>
              The controller is BreakthroughX Health GmbH,
              Rosenthaler Str. 13, 10119 Berlin, registered with the commercial
              register of local court (Amtsgericht) Charlottenburg under HRB
              192700 B, represented by the managing directors Bazil Azmil and
              Stefano Palazzo (“we/us/our” or “Breakthrough Health”). We offer
              services to our users’ (the “User/you/your”) on our website{" "}
              <a href="https://breakthrough.health/">
                https://breakthrough.health/
              </a>{" "}
              (each a “Website”) as well as related services (jointly the
              “Service”).
            </p>
            <p>
              For any questions about data protection you may contact us via{" "}
              <a href="mailto:privacy@breakthrough.health">
                privacy@breakthrough.health
              </a>
              .
            </p>
            <h3>Data protection officer</h3>
            <p>
              We have appointed an external Data Protection Officer provided by
              TechGDPR (<a href="https://techgdpr.com">https://techgdpr.com</a>).
              Our Data Protection Officer is Silvan Jongerius, and can be
              contacted at <a href="mailto:privacy@breakthrough.health">privacy@breakthrough.health</a>.
            </p>
            <h3>
              <a name="short">In short</a>
            </h3>
            <h4>Controller</h4>
            <p>
              BreakthroughX Health GmbH,
              <br />
              c/o Atlantic Labs, Rosenthaler Str. 13, 10119 Berlin,
              <br />
              Registered with the commercial register of local court
              (Amtsgericht) Charlottenburg under HRB 192700 B,
              <br />
              Represented by the managing directors Bazil Azmil and Stefano
              Palazzo
              <br />
              Email:{" "}
              <a href="mailto:info@breakthrough.health">
                info@breakthrough.health
              </a>
              <br />
              Tel.: <a href="+4915255411112">+49 152 554 11112</a>
              <br />
            </p>
            <h4>
              Purpose and Legal Basis of Processing Data; Provision and
              Recipients of Data
            </h4>
            <p>Your data will be used for the following purposes:</p>
            <ul>
              <li>to provide the functioning Website,</li>
              <li>
                to implement this privacy policy and carrying out the
                contractual relationship and our Service,
              </li>
              <li>
                to analyze your use of our Service and improve our Service with
                our legitimate interests of marketing and fraud prevention, or
              </li>
              <li>
                as otherwise explained in this privacy policy or by any
                communication by us.
              </li>
            </ul>
            <p>
              Furthermore, your data will be processed by us with your explicit
              consent for the purpose of sending newsletters you subscribed for
              via the Website.
            </p>
            <p>
              Applicable legal provisions are in particular those of the
              regulation (EU) 2016/679 of the European Parliament and Council of
              27 April 2016, repealing the directive 95/46/EC, on the protection
              of individuals with regard to the processing of personal data, on
              the free movement of such data (“General Data Protection
              Regulation”, GDPR) as well as in the Federal Data Protection Act
              (Bundesdatenschutzgesetz, BDSG) and the German Telemedia Act
              (Telemediengesetz, TMG).
            </p>
            <p>
              We as well as our external service partners receive your data for
              processing those for the purpose of providing our Service. You
              provide data if this is necessary for the aforementioned purposes.
              In the event you refrain from providing such data you may face
              legal disadvantages, for example, limited or no possibility of
              using our Service.
            </p>
            <h4>Transfer of Data outside of the EU</h4>
            <p>
              In course of data processing by us data may be transferred to
              third countries, i.e. countries outside the EU. This may happen
              via implementation of third party providers such as cloud services
              and external service partners which process data on our behalf.
            </p>

            <h4>Your Rights</h4>
            <p>
              You have the right to withdraw your consent relating to the use of
              data any time with effect for the future when such data processing
              is based in your consent.
            </p>
            <p>
              You are entitled to access the data stored by us and are also
              entitled to amend or rectify your data if such data are incorrect.
            </p>
            <p>
              You have the right to object to the processing of your personal
              data, for example if your personal data are processed for direct
              marketing purposes.
            </p>
            <p>You are entitled to request the erasure of your data.</p>
            <p>
              You are entitled to receive information about the stored data (in
              a structured, current and machine-readable format) at any time and
              to request the correction or deletion of the data in case of
              incorrect data storage.
            </p>
            <p>
              You have also the right to lodge a complaint with a supervisory
              authority at your choice. An overview of the European National
              Data Protection Authorities may be found here:{" "}
              <a
                href="http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080
              </a>
            </p>
            <h4>Period for Storing Data; Deletion</h4>
            <p>
              The data are deleted if such data are no longer necessary for the
              purpose of processing.
            </p>
            <h4>Automated Decision making („profiling“)</h4>
            <p>
              In general we do not process any data via “profiling” or in the
              form of automated decision making via the Website or Service.
              However, such profiling may happen by third party providers
              through the Website or Service. We will inform you about such fact
              if possible.
            </p>
            <h4>Data Security</h4>
            <p>
              We have implemented sufficient measures to ensure data and IT
              security. The Website is operated through a safe SSL-connection.
              If an SSL-connection is activated third parties are prevented from
              reading any data that are transferred by you to us.
            </p>
            <h3>
              <a name="detailed">More detailed information</a>
            </h3>
            <ol type="I">
              <li>
                <a href="#I">What are Personal Data?</a>
              </li>
              <li>
                <a href="#II">
                  How are my Data processed when visiting the Website and you
                  contacting us? Does “Profiling” take place?
                </a>
              </li>
              <li>
                <a href="#III">
                  How are my Data processed when using the Breakthrough Health
                  Online Surveys and Service?
                </a>
              </li>
              <li>
                <a href="#IV">
                  What Third Party Services, Cookies, Analytics and Social
                  Plugins does the Website use?
                </a>
              </li>
              <li>
                <a href="#V">Are my Data transferred to Third Parties?</a>
              </li>
              <li>
                <a href="#VI">Are my Data transferred outside the EU?</a>
              </li>
              <li>
                <a href="#VII">
                  Your Rights: Right to access, rectification, object and
                  erasure; right to restriction of processing, right to
                  withdraw, right to data portability, right to lodge a
                  complaint; Duration of the storage of personal data and
                  Deletion Periods
                </a>
              </li>
              <li>
                <a href="#VIII">
                  Data Security; Access and Changes to this Privacy Policy;
                  Contact Details
                </a>
              </li>
            </ol>
            <h4>
              <a name="I">What are Personal Data?</a>
            </h4>
            <p>
              Personal data are any information relating to an identified or
              identifiable natural person. Personal data include e.g. name,
              email address or telephone number. Personal data also includes
              information about hobbies, memberships or websites viewed.
            </p>
            <p>
              We will only collect, use and/or pass on personal data if this is
              permitted by law or if the User consents to the data processing.
            </p>
            <h4>
              <a name="II">
                How are my Data processed when visiting the Website and you
                contacting us? Does “Profiling” take place?
              </a>
            </h4>
            <h5>Visiting the Website</h5>
            <p>
              If you browse our Website the provider of the website collects and
              stores information automatically in so-called “server-log-files”
              that your browser transfers to us. These are:{" "}
              <i>
                Type/version of the browser, system software used, referrer URL,
                hostname of the device, time of the server request, IP-address
                or other unique device identifier.
              </i>
            </p>
            <p>
              We use these data only for statistical analysis for the purpose of
              operation, security and optimization of our Website. However, we
              reserve the right to check these data retrospectively if there is
              a justified suspicion of illegal use based on concrete
              indications. These data is then stored because this is the only
              way to prevent the misuse of our Website and, if necessary, allow
              us to investigate any crimes committed. The storage of these data
              is necessary in order to protect us as the entity responsible for
              processing the data.
            </p>
            <p>
              This data processing is based on Art. 6 (1) c. GDPR for providing
              a secure and stable Website and Service. The data processing may
              also be based on Art. 6 (1) f. GDPR or TMG and we wish to achieve
              the legitimate interests of stabilizing and improving our Website
              and quality insurance.
            </p>
            <h5>Contacting us</h5>
            <p>
              When contacting us via email, the User’s details are stored for
              the purpose of processing the enquiry and, if applicable,
              follow-up questions based on your consent based on the legal basis
              of Art. 6 (1) a. GDPR or fulfilling your request based on Art. 6
              (1) b. GDPR.
            </p>
            <h5>Subscribing for our Newsletter via the Website</h5>
            <p>
              With the newsletter we inform the user about the Website, our
              Service and us.
            </p>
            <p>
              When registering for the newsletter, you have to provide an email
              address. This email address will be transmitted to and stored by
              us (or a provider as specified below).
            </p>
            <p>
              After registration, you will receive an email to confirm the
              registration (“double opt-in”). Via clicking the registration link
              you have given your consent to the processing of your personal
              data for receiving our newsletter according to Art. 6 (1) a. GDPR
              and we may process such data accordingly.
            </p>
            <p>
              In case of registration for the newsletter we (or our provider as
              specified below) also store the IP address, the device name, the
              mail provider as well as the user's first and last name and the
              date of registration.
            </p>
            <h5>
              <a name="mailchimp">
                Use of Mailchimp; Transfer of Data outside the EU
              </a>
            </h5>
            <p>
              The mail provider <b>“Mailchimp”</b> by Rocket Science Group LLC,
              675 Ponce De Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA
              receives and processes on our behalf the data necessary for the
              order, in particular email address, IP address, device name. These
              data are processed on servers in the USA. Mailchimp is certified
              according to “privacy shield”. The “privacy shield” is an
              agreement between the European Union (EU) and the USA to ensure
              compliance with European data protection standards in the USA.
            </p>
            <p>
              Mailchimp is a service with which the dispatch of newsletters can
              be organized and analyzed. With the help of Mailchimp we can
              analyze our newsletter campaigns. When you open an e-mail sent
              with Mailchimp, a file contained in the e-mail (so-called web
              beacon) connects to the Mailchimp servers in the USA. This allows
              one to determine whether a newsletter message has been opened and
              which links have been clicked on. In addition, technical
              information is recorded (e.g. time of registration, IP address,
              browser type and operating system). This information cannot be
              assigned to the respective newsletter recipient. They are used
              exclusively for statistical analysis of newsletter campaigns. The
              results of these analyses can be used to better adapt future
              newsletters to the interests of the recipients.
            </p>
            <p>
              If you do not want Mailchimp to analyze your data, you must
              unsubscribe from the newsletter. For this purpose, we provide a
              respective link in every newsletter.
            </p>
            <p>
              Details on Mailchimp and its privacy policy can be found here:{" "}
              <a
                href="https://mailchimp.com/legal/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://mailchimp.com/legal/privacy/
              </a>
            </p>
            <p>
              The data that are stored for the purpose of newsletter
              subscription will be stored by us and Mailchimp until you
              unsubscribe from the newsletter and will be deleted from our
              servers as well as from the servers of Mailchimp after you
              unsubscribe from the newsletter. The legal basis for processing
              such data by Mailchimp and us is Art. 6 (1) a. or f. GDPR with our
              legitimate interest of marketing. Data stored by us for other
              purposes (e.g. email address for the use of our Service) remain
              unaffected.
            </p>
            <p>
              <b>
                <u>
                  You can withdraw your consent to the processing of your data,
                  the email address and their respective use for sending the
                  newsletter and analyzing the newsletter campaigns at any time.
                  This can be done free of charge (except for the transmission
                  costs) and via a link in the newsletter itself or notification
                  to us or, if applicable, to Mailchimp.
                </u>
              </b>
            </p>
            <h5>Information Emails after use of our Service</h5>
            <p>
              If you purchase goods or services from us, we will send you
              information emails for similar goods or services in the future.
            </p>
            <p>
              In these emails{" "}
              <a href="#mailchimp">we use the services by Mailchimp</a> to
              analyze our email campaigns.
            </p>
            <p>
              You may at any time request that you no longer receive such
              information emails from us and that such emails are not analyzed
              by Mailchimp and us. Please send us a message or click on the link
              at the end of the respective email. This is free of charge other
              than the transmission costs according to the basic tariffs.
            </p>
            <p>
              The data will be processed on the basis of the provisions of the
              German Unfair Competition Act (Gesetz gegen den unlauteren
              Wettbewerb, UWG), your consent (Art. 6 (1) a. GDPR) or for the
              purpose of processing your request (Art. 6 (1) b. GDPR).
            </p>
            <h5>
              <a name="profiling">Automated Decision Making (“Profiling”)</a>
            </h5>
            <p>
              In general we do not process any data via “profiling” or in form
              of automated decision making via the Website or Service. However,
              such profiling may happen by third party providers through the
              Website or Service. We will inform you about such fact if
              possible.
            </p>
            <p>
              Profiling means any automated processing of personal data
              consisting in the use of such personal data to evaluate certain
              personal aspects relating to a natural person, in particular to
              analyse or predict aspects relating to the performance of work,
              economic situation, health, personal preferences, interests,
              reliability, behaviour, location or relocation of that natural
              person. Examples of such profiling include the analysis of data
              (e.g. based on statistical methods) with the aim of displaying
              personalized advertising to the user or giving shopping tips. The
              data subject shall not be subject to a decision based exclusively
              on automated processing, including profiling, which has legal
              effect against them or significantly affects them in a similar
              manner. This shall not apply where the decision (i) is necessary
              for the conclusion or performance of a contract between the data
              subject and the data controller, (ii) is admissible under Union or
              Member State law to which the data controller is subject and where
              such law contains appropriate measures to safeguard the rights,
              freedoms and legitimate interests of the data subject or (iii) is
              taken with the data subject's express consent. In such exceptional
              cases, the person responsible shall take appropriate measures to
              safeguard the rights, freedoms and legitimate interests of the
              data subject, including at least the right to obtain the
              intervention of a person by the data subject, to state his own
              position and to challenge the decision.
            </p>
            <h4>
              <a name="III">
                How are my Data processed when using the Breakthrough Health
                Online Surveys and Service?
              </a>
            </h4>
            <h5>Online Surveys and use of the Service</h5>
            <p>
              For taking part in our online surveys through our Website you are
              asked to provide us with certain data. Such data will only be sent
              and provided to us after you clicked the respective ‘submit’
              button on the Website.
            </p>
            <p>
              These data may include the following:
              <i>region, gender, health information and conditions</i>
            </p>
            <p>
              Depending on the data you share these may be anonymous and
              therefore not considered personal data.
            </p>
            <p>
              If the data processed for the survey are considered personal data,
              such data processing is based on Art. 6 (1) b. or f. GDPR for the
              purpose of providing our service and analyzing those data based on
              our legitimate interests of improving our product and research
              purposes. If and as applicable, the processing of health data is
              based on your explicit consent for the purpose of taking part in
              the survey and analyzing those data for improving our product and
              research purposes on the legal basis of Art. 9 (2) a. GDPR or the
              legal basis of Art. 9 (2) i. GDPR if the processing is necessary
              for reasons of public interest in the area of public health. If
              the processing of your health data is based on your consent, you
              have the right to withdraw your consent relating to the use of
              such health data any time with effect for the future when such
              data processing is based in your consent. For such withdrawal
              please send us an email to privacy@breakthrough.health.
            </p>
            <p>
              We may contact you via email if you send us a request as well as
              for purposes related to taking part in online surveys and/or the
              use of the Service based on Art. 6 (1) b. GDPR, German Unfair
              Competition Act (Gesetz gegen den unlauteren Wettbewerb, UWG) or
              §§ 14, 15 TMG.
            </p>
            <p>
              We also use the information collected, including your personal
              data, in order to improve and analyze your use of our Service
              based on §§ 14, 15 TMG, Art. 6 (1) b. and f. GDPR) and to ensure
              the technical functionality of our services fulfillment of
              contractual or pre-contractual obligations (based on §§ 14, 15 TMG
              or Art. 6 (1) b. GDPR and as otherwise explained in this privacy
              policy. Regarding the data processing based on Art. 6 (1) f. GDPR
              we wish to achieve the legitimate interests of quality insurance
              and marketing.
            </p>
            <p>
              In these surveys, <a href="#typeform">we may use the services by Typeform</a>.
            </p>
            <h4>
              <a name="IV">
                What Third Party Services, Cookies, Analytics and Social Plugins
                does the Website use?
              </a>
            </h4>
            <h5>Cookies</h5>
            <p>
              In order to offer you a convenient online service featuring
              numerous functions, our Website uses text files (“Cookies”)
              containing information to identify returning visitors for the
              duration of their visit to our Website. Cookies are usually saved
              on your device and do not cause any harm. Cookies facilitate the
              transfer of specific content, such as entering data, which has
              already been supplied, and help us identify popular sections of
              our Website.
            </p>
            <p>
              The processing of data when using Cookies is based on our
              legitimate interests of a statistical analysis of the User
              relationship for marketing and quality assurance purposes
              according to Art. 6 (1) f. GDPR or TMG.
            </p>
            <p>
              <b>
                <i>OPT-OUT:</i>
              </b>{" "}
              You can deactivate the use of Cookies in the settings of your
              browser at any time. To find out how to change the settings,
              please consult the help function of your browser. Users may also
              manage a lot of online Cookies by different businesses on the
              US-website{" "}
              <a
                href="http://www.aboutads.info/choices/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.aboutads.info/choices/
              </a>{" "}
              or the EU-website{" "}
              <a
                href="http://www.youronlinechoices.com/uk/your-ad-choices/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.youronlinechoices.com/uk/your-ad-choices/
              </a>
              . However, we want to point out that without Cookies the use and
              comfort of use of our services may be restricted.
            </p>
            <h5>
              <a name="google">Google Analytics</a>
            </h5>
            <p>
              We use Google Analytics a web analytics tool offered by Google
              LLC, Mountain View, CA, USA (“Google“). This analysis service uses
              so-called “cookies”. For analysis, text files will be stored on
              your device. The information stored in the corresponding files
              about the use of this website are generally transmitted and stored
              in Google server in the USA. As the IP anonymization is active on
              this Website, your IP address will be shortened by Google within
              the member states of the European Union (EU). This information
              will be used to evaluate your use of the services offered here and
              enable the operator of this website to analyze your website
              activity and provide other services associated with the website
              service. The IP address transmitted from your browser, as part of
              Google Analytics will not be merged with other data from Google.
            </p>
            <p>
              Adjusting the settings of your browser software can prevent the
              use of cookies. In this case, it may be possible that the
              functions of the service offered here cannot be used in their
              entirety. Furthermore, it is possible to prevent the acquisition
              and processing of data generated by the “cookies” in relation to
              the use of this website, by downloading and installing the browser
              plugin available at the following link:{" "}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
            </p>
            <p>
              We also use Google Analytics to analyse data of Google Adwords for
              statistical purposes.{" "}
              <b>
                <i>OPT-OUT:</i>
              </b>{" "}
              <a
                href="http://www.google.com/settings/ads/onweb/?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.google.com/settings/ads/onweb/?hl=de
              </a>
            </p>
            <p>
              We point out that an{" "}
              <a href="#profiling">“automated decision making (profiling”)</a>{" "}
              can take place when integrating Google and an existing Google
              account.{" "}
              <b>
                <i>OPT-OUT:</i>
              </b>{" "}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
            </p>
            <h5>Google Adwords and Conversion Tracking</h5>
            <p>
              We use the tool Adwords from Google LLC, Mountain View, CA, USA
              for advertising purposes on our website, which serves as an
              analysis service for forwarding and/or visiting websites
              (“conversion tracking”). If you have reached our website via an ad
              from Google, a cookie is stored on your computer. Cookies are
              small text files that your Internet browser stores on your
              computer. These conversion tracking cookies are valid for 30 days.
              If you visit certain sub-pages of our website while the cookie has
              not expired, we and Google may recognize that you as a user have
              clicked on one of our ads on Google and have been redirected to
              our website.
            </p>
            <p>
              The information collected through conversion tracking cookies is
              used by Google to compile statistics on visits to our website.
              These statistics allow us to know the total number of visitors who
              have clicked on our ad and also to see which sub-pages of our
              website have been called up afterwards. However, we do not receive
              any personally identifiable information from Google through this
              feature. Insofar as the IP address is processed, the data is
              processed on the legal basis Art. 6 (1) f. GDPR or TMG, whereby
              our legitimate interests (or the legitimate interests of third
              parties commissioned by us) of quality assurance or statistical
              analysis of user behaviour are pursued.
            </p>
            <p>
              <b>
                <i>OPT-OUT:</i>
              </b>{" "}
              You can prevent the installation of cookies by setting your
              browser accordingly, for example by setting your browser to
              deactivate the automatic setting of cookies generally or
              specifically only block cookies from the "googleadservices.com"
              domain.
            </p>
            <p>
              We point out that an{" "}
              <a href="#profiling">automated decision making (“profiling”)</a>{" "}
              can take place when integrating Google or an existing Google
              account.{" "}
              <b>
                <i>OPT-OUT:</i>
              </b>{" "}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
            </p>
            <p>
              You can also find more information in Google's privacy policy:
              services.google.com/sitestats/en.html
            </p>
            <h5><a name="typeform">Typeform</a></h5>
            <p>
                We use the “Typeform” service of TYPEFORM, S.L., Carrer Bac de Roda, 163, 08018 Barcelona,
                EU to run surveys. For this purpose Typeform may process your data (including
                the IP address, answers to questions, email address, cookies) on servers in the USA. The data
                is processed based on your consent based on the legal basis of Art. 6 (1) a. GDPR or
                fulfilling your request based on Art. 6 (1) b. GDPR.
            </p>
            <p>
                You can also find more information in Typeform's privacy policy:
                <a href="https://admin.typeform.com/to/dwk6gt" target="_blank" rel="noopener noreferrer">https://admin.typeform.com/to/dwk6gt</a>
            </p>
            <h5>Google Fonts</h5>
            <p>
              Our Website uses the “Google Fonts” service of Google LLC,
              Mountain View, CA, USA to integrate and display text on the
              website. For this purpose Google may process your data (including
              the IP address) on servers in the USA. Insofar as the IP address
              is processed, the data is processed on the legal basis Art. 6 (1)
              f. GDPR or TMG, whereby our legitimate interests (or the
              legitimate interests of third parties commissioned by us) of
              quality assurance or statistical analysis of user behaviour are
              pursued.
            </p>
            <p>
              If your browser does not support Google Fonts, a standard font is
              used by your device.
            </p>
            <p>
              We point out that an{" "}
              <a href="#profiling">automated decision making ("profiling")</a>{" "}
              can take place when integrating Google and an existing Google
              account.{" "}
              <b>
                <i>OPT-OUT:</i>
              </b>{" "}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
            </p>
            <p>
              You can also find more information in Google's privacy policy:{" "}
              <a
                href="http://services.google.com/sitestats/en.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://services.google.com/sitestats/en.html
              </a>
            </p>
            <h5>Mailchimp</h5>
            <p>
              We use the services by Mailchimp for sending newsletters and
              emails. For details on Mailchimp please refer to{" "}
              <a href="#mailchimp">
                Use of Mailchimp; Transfer of Data outside the EU
              </a>
              .
            </p>
            <h4>
              <a name="V">Are my Data transferred to Third Parties?</a>
            </h4>
            <p>
              We will transfer your personal data to a third party only within
              the scope of legal provisions, i.e. if we are obliged to transfer
              the data due to a government or court order, or, if applicable,
              legal provisions authorize the transfer or if you give your
              explicit consent.
            </p>
            <p>
              For more information please refer to{" "}
              <a href="mailto:privacy@breakthrough.health">
                privacy@breakthrough.health
              </a>
              .
            </p>
            <h4>
              <a name="VI">Are my Data transferred outside the EU?</a>
            </h4>
            <p>
              When visiting the Website and using our Service data may be
              transferred to countries outside the EU whereas the services by{" "}
              <a href="#google">Google</a> and{" "}
              <a href="#mailchimp">Mailchimp</a> are affected.
            </p>
            <p>
              The US companies providing the services of Google and Mailchimp
              are each certified under EU-US-Privacy-Shield and comply with data
              protection standards applicable in the EU. For more information on
              EU-US-Privacy-Shield and details about the certificates for Google
              and Mailchimp please refer to:{" "}
              <a
                href="https://www.privacyshield.gov"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov
              </a>
            </p>
            <p />
            <h4>
              <a name="VII">
                Your Rights: Right to access, rectification, object and erasure;
                right to restriction of processing, right to withdraw, right to
                data portability, right to lodge a complaint; Duration of the
                storage of personal data and Deletion Periods
              </a>
            </h4>
            <p>
              <b>As a data subject you have the right:</b>
            </p>
            <ul>
              <li>
                to withdraw your consent to us at any time. As a result, we are
                no longer allowed to continue the processing of data based on
                this consent in the future;
              </li>
              <li>
                to object to the processing of your personal data, if your
                personal data are processed on the basis of legitimate interests
                pursuant to Art. 6 (1) f. GDPR insofar as there are reasons for
                this arising from your particular situation;
              </li>
              <li>
                to obtain from us access to your personal data. In particular,
                you may request access to the following information: the
                purposes of the processing; the categories of personal data
                concerned; the recipients or categories of recipients to whom
                the personal data have been or will be disclosed; where
                possible, the envisaged period for which the personal data will
                be stored; the existence of the right to request from the
                controller rectification or erasure of personal data or
                restriction of processing of personal data concerning the data
                subject or to object to such processing; the right to lodge a
                complaint with a supervisory authority; where the personal data
                are not collected from the data subject, any available
                information as to their source and the existence of automated
                decision-making, including profiling and meaningful information
                about this event;
              </li>
              <li>
                to obtain from us without undue delay the rectification of
                inaccurate personal data concerning you;
              </li>
              <li>
                to obtain the erasure of your personal data stored with us,
                unless the processing is necessary to exercise the right to free
                expression of opinion and information, to fulfill a legal
                obligation, for reasons of public interest or to assert,
                exercise or defend legal claims;
              </li>
              <li>
                to demand the restriction of the processing of your personal
                data, if the accuracy of the data is disputed by you, the
                processing is unlawful, but you refuse its deletion and we no
                longer need the data, but you need it to assert, exercise or
                defend legal claims or you have filed an objection against the
                processing; and
              </li>
              <li>
                to receive your personal data, which you have provided to us, in
                a structured, current and machine-readable format or to request
                the transmission to another controller.
              </li>
            </ul>
            <p>
              If you wish to make use of your rights mentioned above please send
              an email to{" "}
              <a href="mailto:privacy@breakthrough.health">
                privacy@breakthrough.health
              </a>
              .
            </p>
            <h5>Right to lodge a complaint</h5>
            <p>
              Each user has a right to lodge a complaint vis-á-vis a supervisory
              authority of his/her choice.
            </p>
            <p>
              An overview of the European National Data Protection Authorities
              may be found here:{" "}
              <a
                href="http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080
              </a>
            </p>
            <h5>
              Duration of the storage of personal data and Deletion Periods
            </h5>
            <p>
              As a rule, we only store your personal data for as long as it is
              necessary for the execution of the contract or the respective
              purpose and limit the storage period to an absolutely necessary
              minimum. Your IP-address and server-log-files (as set forth above)
              are stored for seven (7) days for security and technical reasons.
            </p>
            <p>
              In the case of long-term contractual relationships, such as the
              use of our Website, these storage periods may vary, but are
              generally limited to the duration of the contractual relationship
              or, with regard to the inventory data, to the maximum legal
              retention periods (e.g. in accordance with the German Commercial
              Code (Handelsgesetzbuch, HGB) and the Tax Code (Abgabenordnung,
              AO)).
            </p>
            <p>
              Criteria for the storage period include whether the data are still
              up-to-date, whether the contractual relationship with us still
              exists, whether an inquiry has already been processed, whether a
              process has been completed or not, and whether legal retention
              periods for the personal data concerned are relevant or not.
            </p>
            <h4>
              <a name="VIII">
                Data Security; Access and Changes to this Privacy Policy;
                Contact Details
              </a>
            </h4>
            <h5>Data Security</h5>
            <p>
              We have installed technical and organizational measures in order
              to safeguard our Website and/or Service against loss, destruction,
              access, changes or the distribution of your data by unauthorized
              persons. However we cannot guarantee a complete protection for
              data transmitted to us against all dangers at all times, because
              transmission of information via the internet is not completely
              secure.
            </p>
            <p>
              The Website is operated through a safe SSL-connection. If an
              SSL-connection is activated third parties are prevented from
              reading any data that are transferred by you to us.
            </p>
            <p>
              We will store your data on servers, which are located within the
              European Union and, as applicable and set forth in this privacy
              policy, in the USA.
            </p>
            <h5>Access and Changes to this Privacy Policy</h5>
            <p>
              This privacy policy is accessible via our Website under{" "}
              <a href="https://breakthrough.health/">
                https://breakthrough.health/
              </a>{" "}
              and may be downloaded and printed anytime.
            </p>
            <p>
              We reserve the right to change the regulations of this privacy
              policy at any time, taking into account currently applicable data
              protection provisions. In case of any changes, you will be
              notified and you will have to agree to the modified provisions.
            </p>
            <h5>Contact Details</h5>
            <p>
              For any inquiries and additional questions about processing
              personal data please contact{" "}
              <a href="mailto:privacy@breakthrough.health">
                privacy@breakthrough.health
              </a>
              .<br />
              Further details may be found here:{" "}
              <a href="/imprint">Legal Notice</a>
            </p>
          </Content>
        </Container>
      </section>
    );
  }
}

export default PrivacyPolicy;
