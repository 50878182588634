import React, { Component } from "react";

class BrandIdentity extends Component {
  render() {
    return (
      <div className="bg-petrol color-white py13 mt7-5">
        <div className="wrap color-white">
          <p className="color-candy h6 bold uppercase">2.1 brand identity</p>
          <div className="title col-white mt1 mb1-5">
            A flexible system based on simple shapes
          </div>
          <p className="body">
            The breakthrough brand identity is built on flexibility and clarity.
            The ultimate goal of our graphical style is to convey the right
            messages the right way. Our brand is scientific and yet human.
            Built on flexibility and movement, our identity can use different
            degrees of color and edginess to convey the right messages to the
            right audience.
          </p>
        </div>
      </div>
    );
  }
}

export default BrandIdentity;
