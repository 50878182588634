import React, { Component } from "react";
import SVG from "react-inlinesvg";
import img1 from "./img/shapes.svg";
import pattern from "./img/pattern.svg";
import styled from "styled-components";

const SVGPattern = styled.div`
  path {
    box-shadow: 0 0px 24px 0 rgba(0, 39, 72, 0.22);
  }
`;
class SectionConcept extends Component {
  render() {
    return (
      <div className="pt13">
        <div className="wrap color-petrol">
          <p className="color-candy h6 bold uppercase">2.2 Main Concept</p>
          <div className="title mt1 mb1-5">The squares and the circles</div>
          <p className="body mb3">
            The main idea behind the breakthrough brand is quite simple. Circles
            are the abstraction of the patients, and always use pink colors to
            represent the human side of the company. On the other side, the
            squares represent the data bits. These are strictly using blue
            tones, as they convey Breakthrough’s corporate side and trustworthy
            attitude.
          </p>

          <div className="center my6-75">
            <SVG src={img1} />
          </div>

          <p className="body mb3">
            These basic shapes can increase or decrease in size, in number and
            complexity, creating different visual of varied intensity. From
            simple shapes that create containers, such as on this very website,
            to complex patterns where a more edgy/punchy design is needed, such
            as marketing campaigns or printed brand material.
          </p>
        </div>

        <SVGPattern
          className="center mt6-75"
          style={{
            height: "60vh",
            width: "100vw",
            // background: `url(${pattern})`,
          }}
        >
          <SVG src={pattern} />
        </SVGPattern>
      </div>
    );
  }
}

export default SectionConcept;
