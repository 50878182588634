import React, { Component } from "react";
import styled from "styled-components";
import { color } from "./../../styles/Variables";
import arrowRight from "./../../img/arrowRight.svg";
import SVG from "react-inlinesvg";

const Job = styled.a`
  display: block;
  border: 1px solid ${color.salmon};
  border-radius: 60px;
  padding: 1.8rem 3.5rem;
  margin-bottom: 3rem;
  transition: 0.3s ease;

  button.icon {
    transform: scale(1.3) translate(10px, -6px);
    transform-origin: top;

    > span {
      transform: scale(0.7);
    }
  }

  p {
    transition: 0.4s ease;
  }

  &:hover {
    background: ${color.salmon};
    box-shadow: 0 0 34px 0 rgba(0, 39, 72, 0.27);

    p {
      color: white;
    }
  }

  @media (max-width: 767px) {
    border-radius: 24px;
    padding: 1rem 2rem;

    .w-60 {
      width: 100%;

      ~ div {
        display: none;
      }
    }
  }
`;

class SectionCareers extends Component {
  render() {
    return (
      <div className="wrap mb12">
        <div
          className="grid grid-2 align-center justify-center"
          style={
            window.innerWidth < 768
              ? { width: "100%", gridTemplateColumns: " 100%" }
              : { width: "100%", gridTemplateColumns: "56% 44%" }
          }
        >
          <div id="careers">
            <h2 className="h1-5 font-serif bold col-petrol mb2 line-height-1">
              Careers
            </h2>
            <p className="body line-height-4 color-wg">
              We love to work alongside talented, mission-driven and diverse people who want to have a positive impact on chronic disease and the surrounding research.
              <br />
              <br />
              Can’t find what you’re looking for? Send us an application for other roles at {" "}
              <a
                href="mailto:careers@breakthrough.health"
                title="contact us!"
                className="col-salmon"
              >
                careers@breakthrough.health
              </a>
            </p>
          </div>
          <div />
        </div>

        <div className="mt4" style={{ width: "100%" }}>
          <Job
            href="https://angel.co/company/breakthrough-health-1/jobs"
            title="Experienced Software Engineer (React Native)"
          >
            <div className="w-60 inline-block v-align">
              <p className="body line-height-4 color-cg letter-spacing-06">
                Experienced Software Engineer (React Native)
              </p>
              <p className="base color-wg">Full Time</p>
            </div>

            <div className="inline-block v-align w-40 right-align">
              <button className="inline-block right-align icon bg-salmon pointer p0-8 hover-whiteRed">
                <span className="inline-block v-top" style={{ height: "24px" }}>
                  <SVG src={arrowRight} />
                </span>
              </button>
            </div>
          </Job>

          <a
            href="https://angel.co/company/breakthrough-health-1/jobs"
            target="_blank"
            title="Link to all positions"
            rel="noopener noreferrer"
            className="btn btn-blushpink right mt1"
          >
            See all positions
          </a>
        </div>
      </div>
    );
  }
}

export default SectionCareers;
