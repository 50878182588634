import React, { Component } from "react";
import Hero from "./../../../components/Hero.js";

class BrandApplication extends Component {
  render() {
    return (
      <div>
        <Hero
          title={"Brand Application"}
          text={"How to apply the brand in various contexts."}
        />
      </div>
    );
  }
}

export default BrandApplication;
