import React, { Component } from "react";
import RetinaImage from "react-retina-image";
import { color } from "./../../styles/Variables.js";
import Mood from "./../../img/emilyn.png";
import GooglePlay from "./../../img/google_play.png";
import AppStore from "./../../img/app_store.png";
import styled from "styled-components";

const { SparkScroll } = require("react-spark-scroll-gsap")({
  invalidateAutomatically: true,
});

const Form = styled.form`
  input {
    border: 1px solid ${color.pinkish_grey};
    color: ${color.warm_grey};

    &:focus,
    &:active {
      color: ${color.petrol};
      border-color: ${color.petrol};
    }
  }
`;

class SectionEmilyn extends Component {
  render() {
    return (
      <div  id="emilyn" className="wrap mb12 mmb6">
        <div className="grid grid-2 align-center" style={{ width: "100%" }}>
          <div
            style={
              window.innerWidth >= 768 ? { transform: "translateX(-20px)" } : {}
            }
          >
            <SparkScroll.section
              className="relative"
              timeline={{
                ease: "easeIn",
                topBottom: { opacity: ".2", top: "80px" },
                bottomBottom: { opacity: "1", top: "0px" },
              }}
            >
              <RetinaImage
                style={{ width: "120%"}} src={[Mood, Mood, Mood]}
                checkIfRetinaImgExists={false}
              />
            </SparkScroll.section>
          </div>

          <div>
            <h2 className="h1-5 font-serif bold col-petrol mb2">Meet Emilyn</h2>
            <p className="body body-m line-height-4 color-wg">
              Emilyn is our market leading app that empowers people living with multiple sclerosis to track and understand their condition better, and have more meaningful conversations with their doctors.<br />
              <br />
                Key features include:
              <ul>
                <li>Track and understand MS symptoms over time</li>
                <li>Connect to other people with MS</li>
                <li>Set medication and appointment reminders</li>
                <li>Learn about MS with articles and quizzes</li>
                <li>Securely upload, store, and share health records</li>
                <li>Available in English, Spanish and German</li>
                
              </ul>
              <p className="body body-m line-height-4 color-wg">
                <a target="_blank" href={"https://play.google.com/store/apps/details?id=com.bxh_mvp&utm_source=breakthrough.health&utm_campaign=meet_emilyn"}><RetinaImage
                  style={{ width: 140, marginTop: 0, marginLeft: 20 }} src={[GooglePlay, GooglePlay, GooglePlay]}
                  checkIfRetinaImgExists={false}
                /></a>
                <a target="_blank" href={"https://apps.apple.com/us/app/emilyn-my-ms-companion/id1434712663?mt=8"}><RetinaImage
                  style={{ width: 140, marginTop: 0, marginLeft: 20 }} src={[AppStore, AppStore, AppStore]}
                  checkIfRetinaImgExists={false}
                /></a>
              </p>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionEmilyn;
