import React, { Component } from "react";
import RetinaImage from "react-retina-image";
import Mood from "./../../img/together.jpg";
import Mood2 from "./../../img/together@2x.jpg";
import Mood3 from "./../../img/together@3x.jpg";
import styled from "styled-components";

const { SparkScroll } = require("react-spark-scroll-gsap")({
  invalidateAutomatically: true,
});

const Grid = styled.div`
  @media (max-width: 767px) {
    > div:nth-child(1) {
      order: 2;
    }
    > section:nth-child(2) {
      order: 1;
      transform: translateX(8%);
    }
  }
`;

class SectionTogether extends Component {
  render() {
    return (
      <div className="wrap mb12">
        <Grid
          className="grid grid-2 align-center justify-center"
          style={{ width: "100%" }}
        >
          <div>
            <h2 className="h1-5 font-serif bold col-petrol mb2 line-height-1">
              Let's improve lives together
            </h2>
            <p className="body line-height-4 color-wg">
              Our mission is to improve the lives of people living with autoimmune conditions. To support us on our mission we also work with trusted partners who share our values. 
              <br />
              <br />
              Our capabilities include:
              <ul>
                <li>Patient support programmes</li>
                <li>Patient awareness campaigns</li>
                <li>Clinical trial and study support</li>
              </ul>
            </p>
            <a href="mailto:contact@breakthrough.health" className="mt2-5 btn btn-blushpink">Get in touch</a>
          </div>
          <SparkScroll.section
            className="relative pl2 pml0"
            timeline={{
              ease: "easeIn",
              topBottom: { opacity: ".2", top: "80px" },
              bottomBottom: { opacity: "1", top: "0px" },
            }}
          >
            <div
              style={{
                justifySelf: "end",
              }}
            >
              <RetinaImage
                src={[Mood, Mood2, Mood3]}
                checkIfRetinaImgExists={true}
                style={
                  window.innerWidth >= 768
                    ? { float: "right", width: "83%" }
                    : { float: "right", width: "100%", marginBottom: "3.5rem" }
                }
              />
            </div>
          </SparkScroll.section>
        </Grid>
      </div>
    );
  }
}

export default SectionTogether;
