import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Chapters = styled.div`
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    background: white;
    position: absolute;
    bottom: 0;
    left: 0
    width: 100%;
    height: 23%;
    z-index: -1;
  }
`;

const Grid = styled.div`
  max-width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1.5rem;

  @media (min-width: 1800px) {
    grid-gap: 8rem;
  }

  @media (min-width: 1800px) {
    grid-gap: 4rem;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    /* grid-column-gap: 4rem; */

    .m1,
    .mr1 {
      margin-right: 0;
      margin-left: 0;
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;

    .m1,
    .mr1 {
      margin: 0;
    }
  }
`;

class SectionChapters extends Component {
  render() {
    return (
      <Chapters id="chapters" className="bg-petrol pt5 mb10">
        <div className="wrap color-white">
          <h1 className="title col-white">Chapters</h1>
          <p className="body medium mb3">
            The main topics of Breakthrough’s identity..
          </p>

          <Grid className="grid align-base">
            <div className="card aspect-ratio-box bg-white">
              <Link
                to="/brand-guidelines/brand-positioning"
                className="block py2-25 px2"
              >
                <p className="h4 color-candy">01</p>
                <p className="h4 color-petrol font-serif bold mt1 mb0-5 line-height-2">
                  Brand Positioning
                </p>
                <p className="color-wg h7 line-height-4">
                  How does Breakthrough position in the market, what are its
                  values and vision.
                </p>
              </Link>
            </div>
            <div className="card aspect-ratio-box bg-white">
              <Link
                to="/brand-guidelines/visual-identity"
                className="block py2-25 px2"
              >
                <p className="h4 color-candy">02</p>
                <p className="h4 color-petrol font-serif bold mt1 mb0-5 line-height-2">
                  Visual Identity
                </p>
                <p className="color-wg h7 line-height-4">
                  How does the core of Breakthrough look like? What is its look
                  and feel?
                </p>
              </Link>
            </div>
            <div className="card aspect-ratio-box bg-white">
              <Link
                to="/brand-guidelines/typography-and-colors"
                className="block py2-25 px2"
              >
                <p className="h4 color-candy">03</p>
                <p className="h4 color-petrol font-serif bold mt1 mb0-5 line-height-2">
                  Typography <br />
                  &amp; Colors
                </p>
                <p className="color-wg h7 line-height-4">
                  The baseline of Breakthrough’s identity, its typography and
                  colors.
                </p>
              </Link>
            </div>
            {/* <div className="card aspect-ratio-box bg-white">
              <Link
                to="/brand-guidelines/brand-application"
                className="block py2-25 px2"
              >
                <p className="h4 color-candy">04</p>
                <p className="h4 color-petrol font-serif bold mt1 mb0-5 line-height-2">
                  Brand Application
                </p>
                <p className="color-wg h7 line-height-4">
                  How to apply the brand in various contexts.
                </p>
              </Link>
            </div> */}
          </Grid>
        </div>
      </Chapters>
    );
  }
}

export default SectionChapters;
