import React, { Component } from "react";
import SVG from "react-inlinesvg";
import shapes from "./../../img/strenght-shapes.svg";
import shapesMobile from "./../../img/strenght-shapes-mobile.svg";
import shapesMobilePale from "./../../img/strenght-shapes-mobile-pale.svg";
import styled, { injectGlobal } from "styled-components";
import icoEverything from "./SectionStrengths/ico-everything.svg";
import icoTakeback from "./SectionStrengths/ico-takeback.svg";
import icoTracking from "./SectionStrengths/ico-tracking.svg";
import icoConnect from "./SectionStrengths/ico-connect.svg";

const { SparkScroll } = require("react-spark-scroll-gsap")({
  invalidateAutomatically: true,
});

const Grid = styled.div`
  > div {
    position: relative;
    z-index: 1;
  }

  @media (min-width: 768px) {
    section.relative {
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        display: inline-block;
        position: absolute;
        background: white;

        box-shadow: 0 24px 24px 0 rgba(0, 39, 72, 0.12);
        border-style: solid;
        border-width: 0.5px;
        border-image-source: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0.4) 5%,
          rgba(255, 255, 255, 0) 20%,
          rgba(255, 255, 255, 0)
        );
        border-image-slice: 1;
      }

      .card {
        box-shadow: none;
      }
    }
  }

  @media (min-width: 768px) {
    .card-alt {
      grid-template-columns: 27% calc(100% - 27%);
    }
  }

  @media (max-width: 767px) {
    .card-alt {
      position: relative;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      padding: 4rem 1rem 4rem 2rem;

      img {
        width: 120px;
      }

      .bold {
        font-size: 1.5rem;
        margin-top: 1rem;
      }
    }

    section.relative {
      position: relative;

      &:nth-child(odd) {
        transform: translateX(8%);
      }

      &:nth-child(even) {
        transform: translateX(-8%);
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 130%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100%;
        /* transform: translateY(-50%); */
      }

      &:nth-child(odd) {
        &::before {
          background-image: url(${shapesMobile});
          transform: translate(-62.2%);
          /* transform: translateY(-50%); */
        }
      }

      &:nth-child(even) {
        &::before {
          background-image: url(${shapesMobilePale});
          transform: translate(39.2%);
          /* transform: translateY(-50%); */
        }
      }
    }
  }
`;

injectGlobal`
  @media (max-width: 767px) {
    #strenghtsShapes {
      svg {
        width: 100%;
      }
    }
  }
`;
class SectionStrengths extends Component {
  constructor() {
    super();
    this.state = {
      mobile: window.innerWidth < 768 ? true : false,
      strengthTop: document.getElementById("strenghtsShapes")
        ? document.getElementById("strenghtsShapes").getBoundingClientRect().y
        : 0,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWidth);

    const el = document.getElementById("strenghtsShapes");
    // const top = el ? el.getBoundingClientRect().y : 0;

    this.setState({
      strengthTop: el ? el.getBoundingClientRect().y : 0,
    });
  }

  updateWidth = () => {
    if (window.innerWidth < 768) {
      this.setState({ mobile: true });
    } else {
      this.setState({ mobile: false });
    }
  };

  render() {
    // const strengthTop = this.state.strengthTop;

    return (
      <div id="learn-more" className="relative mb12 mmb10 mmt3">
        <span id="strenghtsShapes">
          {!this.state.mobile ? (
            <SVG
              src={shapes}
              style={{
                position: "absolute",
                top: "69px",
                left: 0,
                zIndex: "-1",
                transform: "translateX(-69%)",
              }}
            />
          ) : (
            <SVG
              src={shapesMobile}
              style={{
                opacity: 0,
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "auto",
                zIndex: "-1",
                transform: "translate(0%, 23%)",
              }}
            />
          )}
        </span>

        <div className="w-100i wrap">
          <h2 className="h1-5 font-serif bold col-petrol mb2">What we do</h2>

          <Grid
            className="grid grid-2 mt4 mmt2"
            style={{ gridGap: "3rem", width: "100%" }}
          >
            <SparkScroll.section
              className="relative"
              timeline={{
                ease: "easeOutQuad",
                topBottom: { opacity: "0", left: "-100px" },
                bottomBottom: { opacity: "1", left: "0px" },
              }}
            >
              <div className="card card-alt py5 px3 bg-white grid">
                <div>
                  <img src={icoEverything} alt="icon" />
                </div>
                <div
                  style={
                    window.innerWidth < 768
                      ? { paddingRight: "2.2rem" }
                      : { paddingLeft: "2rem" }
                  }
                >
                  <p className="bold body mb1 col-petrol">
                    We put people first.
                  </p>
                  <p className="color-wg">
                  People with autoimmune diseases don't define themselves by their illness, and neither do we. Our focus is on the whole person, not just their condition.
                  </p>
                </div>
              </div>
            </SparkScroll.section>

            <SparkScroll.section
              className="relative"
              timeline={{
                ease: "easeOutQuad",
                topBottom: { opacity: "0", left: "100px" },
                bottomBottom: { opacity: "1", left: "0px" },
              }}
            >
              <div className="card card-alt py5 px3 bg-white grid">
                <div>
                  <img src={icoTakeback} alt="icon" />
                </div>
                <div
                  style={
                    window.innerWidth < 768
                      ? { paddingRight: "2.2rem" }
                      : { paddingLeft: "2rem" }
                  }
                >
                  <p className="bold body mb1 col-petrol">We build market leading products.</p>
                  <p className="color-wg">
                    Our first product, Emilyn, is the #1 rated app for multiple sclerosis. We put experience first, using intuitive design and responsive features to build digital products that improve the quality of life of our users.
                  </p>
                </div>
              </div>
            </SparkScroll.section>

            <SparkScroll.section
              className="relative"
              timeline={{
                ease: "easeOutQuad",
                topBottom: { opacity: "0", left: "-100px" },
                bottomBottom: { opacity: "1", left: "0px" },
              }}
            >
              <div className="card card-alt py5 px3 bg-white grid">
                <div>
                  <img src={icoTracking} alt="icon" />
                </div>
                <div
                  style={
                    window.innerWidth < 768
                      ? { paddingRight: "2.2rem" }
                      : { paddingLeft: "2rem" }
                  }
                >
                  <p className="bold body mb1 col-petrol">
                    We know that knowledge is power.
                  </p>
                  <p className="color-wg">
                    Life with a chronic disease is complex. We help people understand their condition and their symptoms, enabling them to advocate for themselves and make informed choices about their health management.                  </p>
                </div>
              </div>
            </SparkScroll.section>

            <SparkScroll.section
              className="relative"
              timeline={{
                ease: "easeOutQuad",
                topBottom: { opacity: "0", left: "100px" },
                bottomBottom: { opacity: "1", left: "0px" },
              }}
            >
              <div className="card card-alt py5 px3 bg-white grid">
                <div>
                  <img src={icoConnect} alt="icon" />
                </div>
                <div
                  style={
                    window.innerWidth < 768
                      ? { paddingRight: "2.2rem" }
                      : { paddingLeft: "2rem" }
                  }
                >
                  <p className="bold body mb1 col-petrol">
                    We provide personalised support.
                  </p>
                  <p className="color-wg">
                    No two peoples' experiences of an autoimmune disease are the same. Our products adapt to our user's situation and provide the right support at the right time.                   </p>
                </div>
              </div>
            </SparkScroll.section>
          </Grid>
        </div>
      </div>
    );
  }
}

export default SectionStrengths;
