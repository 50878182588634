import React, { Component } from "react";

class SectionConcept extends Component {
  render() {
    return (
      <div className="bg-petrol color-white py13 mt7-5">
        <div className="wrap color-white">
          <p className="color-candy h6 bold uppercase">
            3.1 Typography &amp; Color Concept
          </p>
          <div className="title col-white mt1 mb1-5">
            Human + Scientific
            <br />
            Friendly + Corporate
          </div>
          <p className="body">
            The whole typographical and color concept is based on a dialectical
            axis. We intentionally picked both typography and colors that go in
            opposite directions, in order to create a cohesive language that can
            express both the human and the scientific nature of Breakthrough,
            while creating an image that can be both friendly or corporate based
            on how we decide to use the brand elements.
          </p>
        </div>
      </div>
    );
  }
}

export default SectionConcept;
