import React, { Component } from "react";
import { Link } from "react-router-dom";
import arrow from "./../img/arrowRight.svg";
import styled from "styled-components";
import SVG from "react-inlinesvg";

const Container = styled.div`
  position: relative;
  left: 0;
  transition: 0.3s ease;
  transform: translateY(40%);

  &:hover {
    transform: translateY(35%);
    box-shadow: 0 -9px 34px 0 rgba(0, 39, 72, 0.27);
  }

  .title {
    font-size: 3.5rem;
  }

  @media (max-width: 767px) {
    text-align: center;

    .right-align {
      display: none;
    }

    .w-80 {
      width: 100%;
    }

    .title {
      font-size: 2rem;
    }
  }
`;

class NextChapter extends Component {
  componentDidMount() {
    document.getElementById("footer").classList.add("pt9");
  }

  componentWillUnmount() {
    document.getElementById("footer").classList.remove("pt9");
  }

  render() {
    return (
      <Container className="wrap bg-lagoon color-white">
        <Link
          to={this.props.link}
          title="Contact Us!"
          className="block color-white py3-5 px4 pmx1"
        >
          <div className="w-80 inline-block v-align">
            {!this.props.isContact ? (
              <p className="color-candy h6 bold uppercase">Next chapter</p>
            ) : (
              ""
            )}
            <div className="title mt1 mb1-5 color-white">
              {this.props.title}
            </div>
            <p className="body body-m op0-7">{this.props.text}</p>
          </div>

          <span className="inline-block v-align w-20 right-align">
            <span className="inline-block right-align icon bg-salmon hover-whiteRed">
              <span className="inline-block v-top" style={{ height: "24px" }}>
                <SVG src={arrow} />
              </span>
            </span>
          </span>
        </Link>
      </Container>
    );
  }
}

export default NextChapter;
