import React, { Component } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import logo from "./../img/logoFull.svg";
import styled from "styled-components";
import { scroll } from "./../misc/Scroll.js";
import pattern from "./../img/patternWhite.svg";
import arrowRightAlt from "./../img/arrowRightAlt.svg";

const FooterDiv = styled.div`
  position: relative;
  z-index: -1;
  overflow: hidden;

  @media (min-width: 768px) {
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: 0;
      bottom: 0;
      left: 20%;
      width: 200%;
      height: 199.5%;
      transform: rotate(45deg);
      background: url(${pattern}) space 0 0;
      pointer-events: none;
    }
  }

  @media (min-width: 1440px) {
    &::before {
      left: 10%;
    }
  }
`;

const LinkContainer = styled.div`
  grid-auto-flow: column;

  a {
    opacity: 0.7;
    transition: 0.2s ease;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    justify-items: end;
  }

  @media (max-width: 767px) {
    grid-auto-columns: max-content;
    grid-gap: 2rem;
  }
`;

const LogoContainer = styled.div`
  path {
    fill: #fff;
    transition: 0.2s ease-in;
  }

  &:hover {
    path {
      &:nth-child(1) {
        fill: #f7bfdb;
        transition-delay: 0.4s;
      }
      &:nth-child(2) {
        fill: #60bcf4;
        transition-delay: 0.2s;
      }
      &:nth-child(3) {
        fill: #055777;
        transition-delay: 0.2s;
      }
      &:nth-child(4) {
        fill: #ff7a8a;
        transition-delay: 0s;
      }
    }
  }
`;

const GridTop = styled.div`
  grid-template-columns: 1fr 1fr 2fr;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const GridMid = styled.div`
  grid-template-columns: 1fr 1fr 2fr;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 1.3rem;
  }
`;

// const Socials = styled.div`
//   display: flex;
//   justify-content: flex-end;

//   /* display: none; */

//   @media (max-width: 767px) {
//     justify-content: flex-start;
//   }
// `;

class Footer extends Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = event => {
    const el = document.querySelector("#top");

    scroll(el.offsetTop);
  };

  render() {
    return (
      <FooterDiv id="footer" className="pb7 pmb1-5 bg-petrol pmt6">
        <div className="wrap pt5">
          <LogoContainer
            className="inline-block pt2 pb1 medium mb1 pointer"
            onClick={this.handleClick}
          >
            <SVG src={logo} className="inline-block v-align mr1">
              <img src={logo} alt="breakthrough logo" />
            </SVG>
          </LogoContainer>

          <GridTop className="grid color-white mb4 mmb2">
            {/* <div>
              <p className="body medium mb2 mmb1">Products</p>
              <a
                href="/"
                title="Link to Emilyn"
                target="_blank"
                className="color-white op0-7 medium"
              >
                Emilyn
              </a>
            </div> */}
            <div>
              <p className="body medium mb2 mmb1">Company</p>
              <a
                href="https://angel.co/breakthrough-health-1/jobs"
                title="Link to Careers"
                target="_blank"
                rel="noopener noreferrer"
                className="color-white op0-7 medium"
              >
                Careers
              </a>
            </div>
            {/* <div>
              <p className="medium mb2">Sign up for our newsletter</p>
              <form action="post" className="block">
                <input
                  type="email"
                  placeholder="Your email"
                  className="inline-block v-align"
                  required
                  style={{ width: "87%" }}
                />
                <span
                  className="inline-block v-align ml1"
                  style={{
                    width: "9",
                    marginLeft: "4%",
                  }}
                >
                  <button className="inline-block right-align icon bg-white pointer p0-8 hover-redWhite">
                    <span
                      src={arrowRightAlt}
                      alt="Next Chapter"
                      className="inline-block v-top"
                      style={{ height: "24px" }}
                    >
                      <SVG src={arrowRightAlt} />
                    </span>
                  </button>
                </span>
              </form>
            </div> */}
            <div />
            <div className="mmt2">
              <form
                action="https://health.us12.list-manage.com/subscribe/post?u=f2266d16fae39446309d3fcbd&amp;id=3600a5ed16"
                method="post"
              >
                <p className="body medium mb2 mmb1">
                  Sign up for our newsletter
                </p>
                <input
                  type="email"
                  name="EMAIL"
                  placeholder="Email address*"
                  required=""
                  className="inline-block v-align"
                  style={{ width: "80%" }}
                />
                <span
                  className="inline-block v-align ml1"
                  style={
                    window.innerWidth < 768
                      ? {
                          width: "9%",
                          marginLeft: "4%",
                        }
                      : {
                          width: "9%",
                          marginLeft: "11%",
                        }
                  }
                >
                  <button
                    type="submit"
                    value="subscribe"
                    className="inline-block right-align icon bg-white pointer p0-8 hover-redWhite"
                  >
                    <span
                      src={arrowRightAlt}
                      alt="Next Chapter"
                      className="inline-block v-top"
                      style={{ height: "24px" }}
                    >
                      <SVG src={arrowRightAlt} />
                    </span>
                  </button>
                </span>
              </form>
            </div>
            <div />
          </GridTop>

          <GridMid className="grid color-white mt6-75 mmt0 h8 medium align-center">
            <div className="op0-7 hide-m">
              <p className="">&copy; 2018 BreakthroughX Health GmbH</p>
            </div>
            <div />
            <LinkContainer className="grid">
              {/* <Link
                to="/brand-guidelines"
                title="Link to Brand guidelines"
                className="color-white"
              >
                Brand guidelines
              </Link> */}
              <Link
                to="/imprint"
                title="Link to Imprint"
                className="color-white"
              >
                Imprint
              </Link>
              <Link
                to="/privacy-policy"
                title="Link to Privacy Policy"
                className="color-white"
              >
                Privacy Policy
              </Link>
            </LinkContainer>
            {/* <Socials>
              <div
                className="grid"
                style={{
                  gridAutoFlow: "column",
                  gridGap: "1rem",
                }}
              >
                <a
                  href="http://facebook.com"
                  target="_blank"
                  title="Link to Facebook"
                  rel="noopener noreferrer"
                  className="social-icon social-icon--fb"
                >
                  fb
                </a>
                <a
                  href="http://twitter.com"
                  target="_blank"
                  title="Link to Twitter"
                  rel="noopener noreferrer"
                  className="social-icon social-icon--twitter"
                >
                  twitter
                </a>
                <a
                  href="http://instagram.com"
                  target="_blank"
                  title="Link to Instagram"
                  rel="noopener noreferrer"
                  className="social-icon social-icon--insta"
                >
                  insta
                </a>
                <a
                  href="http://medium.com"
                  target="_blank"
                  title="Link to Medium"
                  rel="noopener noreferrer"
                  className="social-icon social-icon--medium"
                >
                  medium
                </a>
              </div>
            </Socials> */}

            <div
              className="show-m grid grid-2 grid-leave pt1"
              style={{
                gridTemplateColumns: "186px 104px",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <div className="op0-7 pt1">
                <p className="">&copy; 2018 BreakthroughX Health GmbH</p>
              </div>
            </div>
          </GridMid>
        </div>
      </FooterDiv>
    );
  }
}

export default Footer;
