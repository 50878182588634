import React, { Component, Fragment } from "react";
import Hero from "./../../components/Hero";
import SectionChapters from "./BrandGuidelines/SectionChapters";
import SectionDownloads from "./BrandGuidelines/SectionDownloads";
import { scroll } from "./../../misc/Scroll.js";
import SVG from "react-inlinesvg";
import arrow from "./../../img/arrow.svg";

class BrandGuidelines extends Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = event => {
    const href = event.target.getAttribute("href");
    const el = document.querySelector(href);

    scroll(el.offsetTop);
  };

  render() {
    return (
      <Fragment>
        <Hero
          title="Brand Identity Guidelines"
          text="An updated resource library to understand and apply the Breakthrough branding correctly."
        />

        <div className="wrap pb10">
          <a
            href="#chapters"
            className="mr2 my0-5 btn btn-sky"
            onClick={this.handleClick}
          >
            Chapters
            <span
              className="ml1"
              href="#chapters"
              style={{ position: "relative", top: "5px" }}
            >
              <SVG src={arrow} />
            </span>
          </a>
          <a
            href="#downloads"
            className="my0-5 btn btn-blushpink"
            onClick={this.handleClick}
          >
            Downloads
            <span
              className="ml1"
              href="#downloads"
              style={{ position: "relative", top: "5px" }}
            >
              <SVG src={arrow} />
            </span>
          </a>
        </div>

        <SectionChapters />
        <SectionDownloads />
      </Fragment>
    );
  }
}

export default BrandGuidelines;
