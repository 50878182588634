import React, { Component, Fragment } from "react";
import Hero from "./../components/Hero.js";
import SectionStrengths from "./Home/SectionStrengths.js";
import SectionEmilyn from "./Home/SectionEmilyn.js";
import SectionTogether from "./Home/SectionTogether.js";
import SectionCareers from "./Home/SectionCareers.js";
import ContactUs from "./../components/ContactUs.js";
import styled, { injectGlobal } from "styled-components";
import { scroll } from "./../misc/Scroll.js";
import shapes from "./../img/hero-shapes.svg";
import { TweenMax, Power0 } from "gsap";

const SectionWrapper = styled.div`
  > div > div {
    width: 50%;

    img {
      width: 70%;
      max-width: 100%;
      height: 100%;
      margin-top: 12%;
      object-fit: cover;
    }

    @media (max-width: 1024px) {
      width: 70%;
    }

    @media (max-width: 767px) {
      width: 90%;

      img {
        width: 100%;
      }
    }
  }
`;

const Fullscreen = styled.div`
  @media (min-width: 768px) {
    height: calc(100vh - 6rem);
    /* height: 100vh; */
    display: grid;
    align-content: center;

    .hero.pt13 {
      padding-top: 0;
    }

    .pb5,
    .pb10 {
      padding-bottom: 0;
    }

    .mb2 {
      margin-bottom: 0;
    }
  }
`;

injectGlobal`
  .heroShapes {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    transform: translate(50%, 0) scale(1);
    transition: .6s ease;
    width: 100%;
    height: 100%;

    .object-container {
      /* transform-origin: center center; */
      /* transform: rotate(45deg); */
    }

    .svg-content {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: center center;
      right: 0;
      bottom: 0;
      /* transform: rotate(45deg); */
      /* animation: barrel2 4s infinite; */
    }

    @media (max-width: 767px) {
      display: none;
      top: 83%;
      right: 50%;
      height: 37vh;
      width: 37vh;
      transform: translate(50%,-50%) scale(1);
      /* transform: scale(0.5) translate(10rem, 15rem); */
      /* transform-origin: center right; */
      /* z-index: 100; */

      .svg-content {

      }
    }

    &.greyed {
      filter: grayscale(1);
      opacity: .5;
    }
  }

  @keyframes barrel {
    to {
      transform: rotate(405deg);
    }
  }

  @keyframes barrel2 {
    0% {
      transform: rotate(45deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(135deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
`;

class Home extends Component {
  handleClick = event => {
    const el = document.querySelector(event.target.getAttribute("href"));

    scroll(el.offsetTop);
  };

  componentDidMount() {
    const duration = 10;

    setTimeout(() => {
      TweenMax.to(".svg-content", duration, {
        rotation: 90,
        ease: Power0.easeNone,
      });

      this.intervalHandler(duration);
    }, 2700);
  }

  intervalHandler = duration => {
    let i = 2;

    setInterval(() => {
      if (document.visibilityState === "visible") {
        TweenMax.to(".svg-content", duration, {
          rotation: 90 * i,
          ease: Power0.easeNone,
        });

        i++;
      } else {
        return;
      }
    }, duration * 2000);
  };

  render() {
    return (
      <Fragment>
        <SectionWrapper className="pt6 pmt0">
          <Fullscreen className="relative">
            <span className="heroShapes">
              <div className="object-container inline-block w-100 h-100">
                <object
                  type="image/svg+xml"
                  data={shapes}
                  width="100%"
                  height="100%"
                  className="svg-content"
                  aria-label="hero-shapes"
                />
              </div>
            </span>

            <div className="w-100i relative pb10 pmb5">
              <Hero
                title={"Digital patient support for autoimmune diseases."}
                text={
                  "We help people track their symptoms, manage their treatment, and connect with the community."
                }
                withCta={true}
              />
            </div>
          </Fullscreen>

          <SectionStrengths />
          <SectionEmilyn />
          <SectionTogether />
          <SectionCareers />

          <ContactUs
            isContact={true}
            title="Contact us"
            text="If you have any questions at all or just want to get in touch, feel free to drop us a line."
            link="mailto:info@breakthrough.health"
          />
        </SectionWrapper>
      </Fragment>
    );
  }
}

export default Home;
